/* Overwrite Bootstrap */
/* Overwrite Bootstrap */
/* Outlines */
/* Overwrite Bootstrap */
/*
 * GLOBAL BOOTSTRAP VARIABLES
 */
/*
 * COMPONENTS (order by alphabetic)
 */
.lnk-section--image .lnk-quotation__author {
  color: #ffffff;
}

.lnk-section--image .lnk-quotation .lnk-carousel .carousel-indicators li {
  border: 1px solid #ffffff;
}

.lnk-section--image .lnk-quotation .lnk-carousel .carousel-indicators li.active {
  background-color: #ffffff;
}

/* Overwrite Bootstrap */
/* Overwrite Bootstrap */
/* Outlines */
/* Overwrite Bootstrap */
/*
 * GLOBAL BOOTSTRAP VARIABLES
 */
/*
 * COMPONENTS (order by alphabetic)
 */
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #5f636e;
  --gray-dark: #343a40;
  --black: #000000;
  --dark: #2d3036;
  --primary: #7fbd27;
  --secondary: #373737;
  --primary30: #e5f1d2;
  --primary10: #f5faed;
  --tertiary: #ffffff;
  --primary: #7fbd27;
  --secondary: #373737;
  --success: #4bbc00;
  --info: #8ed7dd;
  --warning: #ffcc00;
  --danger: #ea182f;
  --light: #f8f9fa;
  --dark: #2d3036;
  --black: #000000;
  --white: #ffffff;
  --primary30: #e5f1d2;
  --primary10: #f5faed;
  --tertiary: #ffffff;
  --gray: #5f636e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Avenir-Roman";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.55;
  color: #2d3036;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, .lnk-cookie-modal__modal-headline, h3, .lnk-cookie-modal__manager-headline, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #7fbd27;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #7fbd27;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, .lnk-cookie-modal__modal-headline, h3, .lnk-cookie-modal__manager-headline, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .lnk-accordion__element-head, .h6 {
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .lnk-cookie-modal__modal-headline, .h2 {
  font-size: 2rem;
}

h3, .lnk-cookie-modal__manager-headline, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5, .lnk-accordion__element-head {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2d3036;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #2d3036;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dbedc3;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #bcdd8f;
}

.table-hover .table-primary:hover {
  background-color: #cfe7af;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #cfe7af;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c7c7c7;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #979797;
}

.table-hover .table-secondary:hover {
  background-color: #bababa;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #bababa;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cdecb8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a1dc7a;
}

.table-hover .table-success:hover {
  background-color: #bfe7a4;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bfe7a4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dff4f5;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c4eaed;
}

.table-hover .table-info:hover {
  background-color: #ccedef;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #ccedef;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff1b8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe47a;
}

.table-hover .table-warning:hover {
  background-color: #ffec9f;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffec9f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9bec5;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f48793;
}

.table-hover .table-danger:hover {
  background-color: #f7a7b0;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f7a7b0;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c5c7;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #929396;
}

.table-hover .table-dark:hover {
  background-color: #b7b8bb;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b7b8bb;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}

.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-primary30,
.table-primary30 > th,
.table-primary30 > td {
  background-color: #f8fbf2;
}

.table-primary30 th,
.table-primary30 td,
.table-primary30 thead th,
.table-primary30 tbody + tbody {
  border-color: #f1f8e8;
}

.table-hover .table-primary30:hover {
  background-color: #eef5df;
}

.table-hover .table-primary30:hover > td,
.table-hover .table-primary30:hover > th {
  background-color: #eef5df;
}

.table-primary10,
.table-primary10 > th,
.table-primary10 > td {
  background-color: #fcfefa;
}

.table-primary10 th,
.table-primary10 td,
.table-primary10 thead th,
.table-primary10 tbody + tbody {
  border-color: #fafcf6;
}

.table-hover .table-primary10:hover {
  background-color: #effae5;
}

.table-hover .table-primary10:hover > td,
.table-hover .table-primary10:hover > th {
  background-color: #effae5;
}

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: white;
}

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: white;
}

.table-hover .table-tertiary:hover {
  background-color: #f2f2f2;
}

.table-hover .table-tertiary:hover > td,
.table-hover .table-tertiary:hover > th {
  background-color: #f2f2f2;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #d2d3d6;
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #acaeb4;
}

.table-hover .table-gray:hover {
  background-color: #c5c6ca;
}

.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #c5c6ca;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.555556em + 0.66666rem + 4px);
  padding: 0.33333rem 0.77778rem;
  font-family: "Avenir-Roman";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.555556;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid #7fbd27;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #7fbd27;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.33333rem + 2px);
  padding-bottom: calc(0.33333rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.555556;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.33333rem 0;
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.555556;
  color: #2d3036;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4bbc00;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1rem;
  line-height: 1.55;
  color: #ffffff;
  background-color: rgba(75, 188, 0, 0.9);
  border-radius: 0rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4bbc00;
  padding-right: calc(1.555556em + 0.66666rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234bbc00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.388889em + 0.166665rem) center;
  background-size: calc(0.777778em + 0.33333rem) calc(0.777778em + 0.33333rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #4bbc00;
  box-shadow: 0 0 0 0.2rem rgba(75, 188, 0, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.555556em + 0.66666rem);
  background-position: top calc(0.388889em + 0.166665rem) right calc(0.388889em + 0.166665rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #4bbc00;
  padding-right: calc(0.75em + 3.277775rem);
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 23 14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M.9375 2.759L2.7813.9374l9.2182 9.107 9.2193-9.107 1.8437 1.8214L12 13.6875l-1.8437-1.8214L.9375 2.7589z' fill='%23008E62' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat right 0.77778rem center/23px 14px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234bbc00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 2.77778rem/calc(0.777778em + 0.33333rem) calc(0.777778em + 0.33333rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #4bbc00;
  box-shadow: 0 0 0 0.2rem rgba(75, 188, 0, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4bbc00;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4bbc00;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #4bbc00;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5fef00;
  background-color: #5fef00;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(75, 188, 0, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4bbc00;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4bbc00;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #4bbc00;
  box-shadow: 0 0 0 0.2rem rgba(75, 188, 0, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ea182f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1rem;
  line-height: 1.55;
  color: #ffffff;
  background-color: rgba(234, 24, 47, 0.9);
  border-radius: 0rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ea182f;
  padding-right: calc(1.555556em + 0.66666rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea182f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea182f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.388889em + 0.166665rem) center;
  background-size: calc(0.777778em + 0.33333rem) calc(0.777778em + 0.33333rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ea182f;
  box-shadow: 0 0 0 0.2rem rgba(234, 24, 47, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.555556em + 0.66666rem);
  background-position: top calc(0.388889em + 0.166665rem) right calc(0.388889em + 0.166665rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ea182f;
  padding-right: calc(0.75em + 3.277775rem);
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 23 14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M.9375 2.759L2.7813.9374l9.2182 9.107 9.2193-9.107 1.8437 1.8214L12 13.6875l-1.8437-1.8214L.9375 2.7589z' fill='%23008E62' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat right 0.77778rem center/23px 14px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea182f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea182f' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 2.77778rem/calc(0.777778em + 0.33333rem) calc(0.777778em + 0.33333rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ea182f;
  box-shadow: 0 0 0 0.2rem rgba(234, 24, 47, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ea182f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ea182f;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ea182f;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ee4759;
  background-color: #ee4759;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(234, 24, 47, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ea182f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ea182f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ea182f;
  box-shadow: 0 0 0 0.2rem rgba(234, 24, 47, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "Avenir-Roman";
  font-weight: 400;
  color: #2d3036;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 13px 24px;
  font-size: 1rem;
  line-height: 1.29;
  border-radius: 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #2d3036;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 189, 39, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #2d3036;
  background-color: #7fbd27;
  border-color: #7fbd27;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #6a9d20;
  border-color: #63931e;
}

.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #6a9d20;
  border-color: #63931e;
  box-shadow: 0 0 0 0.2rem rgba(115, 168, 41, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #2d3036;
  background-color: #7fbd27;
  border-color: #7fbd27;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #63931e;
  border-color: #5b881c;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 168, 41, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #373737;
  border-color: #373737;
}

.btn-secondary:hover {
  color: #ffffff;
  background-color: #242424;
  border-color: #1e1e1e;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #242424;
  border-color: #1e1e1e;
  box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #373737;
  border-color: #373737;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #1e1e1e;
  border-color: #171717;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #4bbc00;
  border-color: #4bbc00;
}

.btn-success:hover {
  color: #ffffff;
  background-color: #3c9600;
  border-color: #378900;
}

.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #3c9600;
  border-color: #378900;
  box-shadow: 0 0 0 0.2rem rgba(102, 198, 38, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #4bbc00;
  border-color: #4bbc00;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #378900;
  border-color: #327c00;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 198, 38, 0.5);
}

.btn-info {
  color: #2d3036;
  background-color: #8ed7dd;
  border-color: #8ed7dd;
}

.btn-info:hover {
  color: #2d3036;
  background-color: #71cdd4;
  border-color: #67c9d1;
}

.btn-info:focus, .btn-info.focus {
  color: #2d3036;
  background-color: #71cdd4;
  border-color: #67c9d1;
  box-shadow: 0 0 0 0.2rem rgba(127, 190, 196, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #2d3036;
  background-color: #8ed7dd;
  border-color: #8ed7dd;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #2d3036;
  background-color: #67c9d1;
  border-color: #5dc6ce;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 190, 196, 0.5);
}

.btn-warning {
  color: #2d3036;
  background-color: #ffcc00;
  border-color: #ffcc00;
}

.btn-warning:hover {
  color: #2d3036;
  background-color: #d9ad00;
  border-color: #cca300;
}

.btn-warning:focus, .btn-warning.focus {
  color: #2d3036;
  background-color: #d9ad00;
  border-color: #cca300;
  box-shadow: 0 0 0 0.2rem rgba(224, 181, 8, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #2d3036;
  background-color: #ffcc00;
  border-color: #ffcc00;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #2d3036;
  background-color: #cca300;
  border-color: #bf9900;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 181, 8, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #ea182f;
  border-color: #ea182f;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: #c91226;
  border-color: #be1124;
}

.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #c91226;
  border-color: #be1124;
  box-shadow: 0 0 0 0.2rem rgba(237, 59, 78, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #ea182f;
  border-color: #ea182f;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #be1124;
  border-color: #b21022;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 59, 78, 0.5);
}

.btn-light {
  color: #2d3036;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #2d3036;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #2d3036;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(218, 219, 221, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #2d3036;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #2d3036;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 219, 221, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #2d3036;
  border-color: #2d3036;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #1c1d21;
  border-color: #16171a;
}

.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #1c1d21;
  border-color: #16171a;
  box-shadow: 0 0 0 0.2rem rgba(77, 79, 84, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #2d3036;
  border-color: #2d3036;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #16171a;
  border-color: #101113;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 79, 84, 0.5);
}

.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-black:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.btn-black:focus, .btn-black.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black.disabled, .btn-black:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-white {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-white:hover {
  color: #2d3036;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus, .btn-white.focus {
  color: #2d3036;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 225, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #2d3036;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 225, 0.5);
}

.btn-primary30 {
  color: #2d3036;
  background-color: #e5f1d2;
  border-color: #e5f1d2;
}

.btn-primary30:hover {
  color: #2d3036;
  background-color: #d4e8b5;
  border-color: #cfe5ab;
}

.btn-primary30:focus, .btn-primary30.focus {
  color: #2d3036;
  background-color: #d4e8b5;
  border-color: #cfe5ab;
  box-shadow: 0 0 0 0.2rem rgba(201, 212, 187, 0.5);
}

.btn-primary30.disabled, .btn-primary30:disabled {
  color: #2d3036;
  background-color: #e5f1d2;
  border-color: #e5f1d2;
}

.btn-primary30:not(:disabled):not(.disabled):active, .btn-primary30:not(:disabled):not(.disabled).active,
.show > .btn-primary30.dropdown-toggle {
  color: #2d3036;
  background-color: #cfe5ab;
  border-color: #c9e2a1;
}

.btn-primary30:not(:disabled):not(.disabled):active:focus, .btn-primary30:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 212, 187, 0.5);
}

.btn-primary10 {
  color: #2d3036;
  background-color: #f5faed;
  border-color: #f5faed;
}

.btn-primary10:hover {
  color: #2d3036;
  background-color: #e4f2cf;
  border-color: #dfefc5;
}

.btn-primary10:focus, .btn-primary10.focus {
  color: #2d3036;
  background-color: #e4f2cf;
  border-color: #dfefc5;
  box-shadow: 0 0 0 0.2rem rgba(215, 220, 210, 0.5);
}

.btn-primary10.disabled, .btn-primary10:disabled {
  color: #2d3036;
  background-color: #f5faed;
  border-color: #f5faed;
}

.btn-primary10:not(:disabled):not(.disabled):active, .btn-primary10:not(:disabled):not(.disabled).active,
.show > .btn-primary10.dropdown-toggle {
  color: #2d3036;
  background-color: #dfefc5;
  border-color: #d9ecbb;
}

.btn-primary10:not(:disabled):not(.disabled):active:focus, .btn-primary10:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 220, 210, 0.5);
}

.btn-tertiary {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-tertiary:hover {
  color: #2d3036;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-tertiary:focus, .btn-tertiary.focus {
  color: #2d3036;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 225, 0.5);
}

.btn-tertiary.disabled, .btn-tertiary:disabled {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
  color: #2d3036;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 225, 0.5);
}

.btn-gray {
  color: #ffffff;
  background-color: #5f636e;
  border-color: #5f636e;
}

.btn-gray:hover {
  color: #ffffff;
  background-color: #4d5159;
  border-color: #474a53;
}

.btn-gray:focus, .btn-gray.focus {
  color: #ffffff;
  background-color: #4d5159;
  border-color: #474a53;
  box-shadow: 0 0 0 0.2rem rgba(119, 122, 132, 0.5);
}

.btn-gray.disabled, .btn-gray:disabled {
  color: #ffffff;
  background-color: #5f636e;
  border-color: #5f636e;
}

.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #474a53;
  border-color: #41444c;
}

.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 122, 132, 0.5);
}

.btn-outline-primary {
  color: #7fbd27;
  border-color: #7fbd27;
}

.btn-outline-primary:hover {
  color: #2d3036;
  background-color: #7fbd27;
  border-color: #7fbd27;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 189, 39, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #7fbd27;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #2d3036;
  background-color: #7fbd27;
  border-color: #7fbd27;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 189, 39, 0.5);
}

.btn-outline-secondary {
  color: #373737;
  border-color: #373737;
}

.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #373737;
  border-color: #373737;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 55, 55, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #373737;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #373737;
  border-color: #373737;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 55, 55, 0.5);
}

.btn-outline-success {
  color: #4bbc00;
  border-color: #4bbc00;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #4bbc00;
  border-color: #4bbc00;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(75, 188, 0, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #4bbc00;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #4bbc00;
  border-color: #4bbc00;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(75, 188, 0, 0.5);
}

.btn-outline-info {
  color: #8ed7dd;
  border-color: #8ed7dd;
}

.btn-outline-info:hover {
  color: #2d3036;
  background-color: #8ed7dd;
  border-color: #8ed7dd;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 215, 221, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #8ed7dd;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #2d3036;
  background-color: #8ed7dd;
  border-color: #8ed7dd;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(142, 215, 221, 0.5);
}

.btn-outline-warning {
  color: #ffcc00;
  border-color: #ffcc00;
}

.btn-outline-warning:hover {
  color: #2d3036;
  background-color: #ffcc00;
  border-color: #ffcc00;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 204, 0, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffcc00;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #2d3036;
  background-color: #ffcc00;
  border-color: #ffcc00;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 204, 0, 0.5);
}

.btn-outline-danger {
  color: #ea182f;
  border-color: #ea182f;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #ea182f;
  border-color: #ea182f;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 24, 47, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ea182f;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ea182f;
  border-color: #ea182f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 24, 47, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #2d3036;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #2d3036;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #2d3036;
  border-color: #2d3036;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #2d3036;
  border-color: #2d3036;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 48, 54, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #2d3036;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #2d3036;
  border-color: #2d3036;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 48, 54, 0.5);
}

.btn-outline-black {
  color: #000000;
  border-color: #000000;
}

.btn-outline-black:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000000;
  background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white:hover {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-primary30 {
  color: #e5f1d2;
  border-color: #e5f1d2;
}

.btn-outline-primary30:hover {
  color: #2d3036;
  background-color: #e5f1d2;
  border-color: #e5f1d2;
}

.btn-outline-primary30:focus, .btn-outline-primary30.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 241, 210, 0.5);
}

.btn-outline-primary30.disabled, .btn-outline-primary30:disabled {
  color: #e5f1d2;
  background-color: transparent;
}

.btn-outline-primary30:not(:disabled):not(.disabled):active, .btn-outline-primary30:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary30.dropdown-toggle {
  color: #2d3036;
  background-color: #e5f1d2;
  border-color: #e5f1d2;
}

.btn-outline-primary30:not(:disabled):not(.disabled):active:focus, .btn-outline-primary30:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary30.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 241, 210, 0.5);
}

.btn-outline-primary10 {
  color: #f5faed;
  border-color: #f5faed;
}

.btn-outline-primary10:hover {
  color: #2d3036;
  background-color: #f5faed;
  border-color: #f5faed;
}

.btn-outline-primary10:focus, .btn-outline-primary10.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 250, 237, 0.5);
}

.btn-outline-primary10.disabled, .btn-outline-primary10:disabled {
  color: #f5faed;
  background-color: transparent;
}

.btn-outline-primary10:not(:disabled):not(.disabled):active, .btn-outline-primary10:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary10.dropdown-toggle {
  color: #2d3036;
  background-color: #f5faed;
  border-color: #f5faed;
}

.btn-outline-primary10:not(:disabled):not(.disabled):active:focus, .btn-outline-primary10:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 250, 237, 0.5);
}

.btn-outline-tertiary {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-tertiary:hover {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-outline-tertiary.dropdown-toggle {
  color: #2d3036;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-gray {
  color: #5f636e;
  border-color: #5f636e;
}

.btn-outline-gray:hover {
  color: #ffffff;
  background-color: #5f636e;
  border-color: #5f636e;
}

.btn-outline-gray:focus, .btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 99, 110, 0.5);
}

.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  color: #5f636e;
  background-color: transparent;
}

.btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #5f636e;
  border-color: #5f636e;
}

.btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 99, 110, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #7fbd27;
  text-decoration: none;
}

.btn-link:hover {
  color: #7fbd27;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #2d3036;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 16px;
  clear: both;
  font-weight: 400;
  color: #2d3036;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #7fbd27;
}

.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #7fbd27;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 16px;
  margin-bottom: 0;
  font-size: 1rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 8px 16px;
  color: #2d3036;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 18px;
  padding-left: 18px;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.33333rem 0.77778rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.555556;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #7fbd27;
  border-radius: 0rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 4px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.77778rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.55rem;
  padding-left: 1.944444rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.444444rem;
  height: 1.497222rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #7fbd27;
  background-color: #7fbd27;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #7fbd27;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #d1eda9;
  border-color: #d1eda9;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.052778rem;
  left: -1.944444rem;
  display: block;
  width: 1.444444rem;
  height: 1.444444rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #7fbd27 solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.052778rem;
  left: -1.944444rem;
  display: block;
  width: 1.444444rem;
  height: 1.444444rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 58 48' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M52.7277.4035L58 5.7735 18.234 46.277l-.0023-.0026-1.1074 1.1291L0 29.9617l5.2723-5.37 11.853 12.0733z' fill='%23ffffff' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #7fbd27;
  background-color: #7fbd27;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(127, 189, 39, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(127, 189, 39, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(127, 189, 39, 0.5);
}

.custom-switch {
  padding-left: 3.027777rem;
}

.custom-switch .custom-control-label::before {
  left: -3.027777rem;
  width: 2.527777rem;
  pointer-events: all;
  border-radius: 0.722222rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.052778rem + 4px);
  left: calc(-3.027777rem + 4px);
  width: calc(1.444444rem - 8px);
  height: calc(1.444444rem - 8px);
  background-color: #7fbd27;
  border-radius: 0.722222rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(1.083333rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(127, 189, 39, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.555556em + 0.66666rem + 4px);
  padding: 0.33333rem 2.77778rem 0.33333rem 0.77778rem;
  font-family: "Avenir-Roman";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.555556;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg viewBox='0 0 23 14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M.9375 2.759L2.7813.9374l9.2182 9.107 9.2193-9.107 1.8437 1.8214L12 13.6875l-1.8437-1.8214L.9375 2.7589z' fill='%23008E62' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat right 0.77778rem center/23px 14px;
  border: 2px solid #7fbd27;
  border-radius: 0rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #7fbd27;
  outline: 0;
  box-shadow: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.77778rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.555556em + 0.66666rem + 4px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.555556em + 0.66666rem + 4px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #7fbd27;
  box-shadow: none;
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.555556em + 0.66666rem + 4px);
  padding: 0.33333rem 0.77778rem;
  font-family: "Avenir-Roman";
  font-weight: 400;
  line-height: 1.555556;
  color: #495057;
  background-color: #ffffff;
  border: 2px solid #7fbd27;
  border-radius: 0rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.555556em + 0.66666rem);
  padding: 0.33333rem 0.77778rem;
  line-height: 1.555556;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0rem 0rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #7fbd27;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #d1eda9;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #7fbd27;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #d1eda9;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #7fbd27;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #d1eda9;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #7fbd27;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.403125rem;
  padding-bottom: 0.403125rem;
  margin-right: 0;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 16px;
  padding-left: 16px;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #7fbd27;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #7fbd27;
}

.navbar-light .navbar-nav .nav-link {
  color: #2d3036;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #7fbd27;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #7fbd27;
}

.navbar-light .navbar-toggler {
  color: #2d3036;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%232d3036' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #2d3036;
}

.navbar-light .navbar-text a {
  color: #7fbd27;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #7fbd27;
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #ffffff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0rem - 1px);
  border-bottom-left-radius: calc(0rem - 1px);
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0rem - 1px) calc(0rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0rem - 1px) calc(0rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0rem - 1px);
  border-bottom-left-radius: calc(0rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #7fbd27;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #7fbd27;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 189, 39, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #7fbd27;
  border-color: #7fbd27;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #2d3036;
  background-color: #7fbd27;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #2d3036;
  background-color: #63931e;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(127, 189, 39, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #373737;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #1e1e1e;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(55, 55, 55, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #4bbc00;
}

a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #378900;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(75, 188, 0, 0.5);
}

.badge-info {
  color: #2d3036;
  background-color: #8ed7dd;
}

a.badge-info:hover, a.badge-info:focus {
  color: #2d3036;
  background-color: #67c9d1;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(142, 215, 221, 0.5);
}

.badge-warning {
  color: #2d3036;
  background-color: #ffcc00;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #2d3036;
  background-color: #cca300;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 204, 0, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #ea182f;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #be1124;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(234, 24, 47, 0.5);
}

.badge-light {
  color: #2d3036;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #2d3036;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #2d3036;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #16171a;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 48, 54, 0.5);
}

.badge-black {
  color: #ffffff;
  background-color: #000000;
}

a.badge-black:hover, a.badge-black:focus {
  color: #ffffff;
  background-color: black;
}

a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-white {
  color: #2d3036;
  background-color: #ffffff;
}

a.badge-white:hover, a.badge-white:focus {
  color: #2d3036;
  background-color: #e6e6e6;
}

a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-primary30 {
  color: #2d3036;
  background-color: #e5f1d2;
}

a.badge-primary30:hover, a.badge-primary30:focus {
  color: #2d3036;
  background-color: #cfe5ab;
}

a.badge-primary30:focus, a.badge-primary30.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 241, 210, 0.5);
}

.badge-primary10 {
  color: #2d3036;
  background-color: #f5faed;
}

a.badge-primary10:hover, a.badge-primary10:focus {
  color: #2d3036;
  background-color: #dfefc5;
}

a.badge-primary10:focus, a.badge-primary10.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 250, 237, 0.5);
}

.badge-tertiary {
  color: #2d3036;
  background-color: #ffffff;
}

a.badge-tertiary:hover, a.badge-tertiary:focus {
  color: #2d3036;
  background-color: #e6e6e6;
}

a.badge-tertiary:focus, a.badge-tertiary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-gray {
  color: #ffffff;
  background-color: #5f636e;
}

a.badge-gray:hover, a.badge-gray:focus {
  color: #ffffff;
  background-color: #474a53;
}

a.badge-gray:focus, a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(95, 99, 110, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #426214;
  background-color: #e5f2d4;
  border-color: #dbedc3;
}

.alert-primary hr {
  border-top-color: #cfe7af;
}

.alert-primary .alert-link {
  color: #25380b;
}

.alert-secondary {
  color: #1d1d1d;
  background-color: #d7d7d7;
  border-color: #c7c7c7;
}

.alert-secondary hr {
  border-top-color: #bababa;
}

.alert-secondary .alert-link {
  color: #040404;
}

.alert-success {
  color: #276200;
  background-color: #dbf2cc;
  border-color: #cdecb8;
}

.alert-success hr {
  border-top-color: #bfe7a4;
}

.alert-success .alert-link {
  color: #132f00;
}

.alert-info {
  color: #4a7073;
  background-color: #e8f7f8;
  border-color: #dff4f5;
}

.alert-info hr {
  border-top-color: #ccedef;
}

.alert-info .alert-link {
  color: #365254;
}

.alert-warning {
  color: #856a00;
  background-color: #fff5cc;
  border-color: #fff1b8;
}

.alert-warning hr {
  border-top-color: #ffec9f;
}

.alert-warning .alert-link {
  color: #524100;
}

.alert-danger {
  color: #7a0c18;
  background-color: #fbd1d5;
  border-color: #f9bec5;
}

.alert-danger hr {
  border-top-color: #f7a7b0;
}

.alert-danger .alert-link {
  color: #4c070f;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #17191c;
  background-color: #d5d6d7;
  border-color: #c4c5c7;
}

.alert-dark hr {
  border-top-color: #b7b8bb;
}

.alert-dark .alert-link {
  color: black;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-black hr {
  border-top-color: #ababab;
}

.alert-black .alert-link {
  color: black;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-primary30 {
  color: #777d6d;
  background-color: #fafcf6;
  border-color: #f8fbf2;
}

.alert-primary30 hr {
  border-top-color: #eef5df;
}

.alert-primary30 .alert-link {
  color: #5d6255;
}

.alert-primary10 {
  color: #7f827b;
  background-color: #fdfefb;
  border-color: #fcfefa;
}

.alert-primary10 hr {
  border-top-color: #effae5;
}

.alert-primary10 .alert-link {
  color: #656862;
}

.alert-tertiary {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-tertiary hr {
  border-top-color: #f2f2f2;
}

.alert-tertiary .alert-link {
  color: #6c6c6c;
}

.alert-gray {
  color: #313339;
  background-color: #dfe0e2;
  border-color: #d2d3d6;
}

.alert-gray hr {
  border-top-color: #c5c6ca;
}

.alert-gray .alert-link {
  color: #191a1e;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #7fbd27;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #2d3036;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #7fbd27;
  border-color: #7fbd27;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #426214;
  background-color: #dbedc3;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #426214;
  background-color: #cfe7af;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #426214;
  border-color: #426214;
}

.list-group-item-secondary {
  color: #1d1d1d;
  background-color: #c7c7c7;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #1d1d1d;
  background-color: #bababa;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #1d1d1d;
  border-color: #1d1d1d;
}

.list-group-item-success {
  color: #276200;
  background-color: #cdecb8;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #276200;
  background-color: #bfe7a4;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #276200;
  border-color: #276200;
}

.list-group-item-info {
  color: #4a7073;
  background-color: #dff4f5;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #4a7073;
  background-color: #ccedef;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #4a7073;
  border-color: #4a7073;
}

.list-group-item-warning {
  color: #856a00;
  background-color: #fff1b8;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856a00;
  background-color: #ffec9f;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #856a00;
  border-color: #856a00;
}

.list-group-item-danger {
  color: #7a0c18;
  background-color: #f9bec5;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7a0c18;
  background-color: #f7a7b0;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #7a0c18;
  border-color: #7a0c18;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #17191c;
  background-color: #c4c5c7;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #17191c;
  background-color: #b7b8bb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #17191c;
  border-color: #17191c;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-primary30 {
  color: #777d6d;
  background-color: #f8fbf2;
}

.list-group-item-primary30.list-group-item-action:hover, .list-group-item-primary30.list-group-item-action:focus {
  color: #777d6d;
  background-color: #eef5df;
}

.list-group-item-primary30.list-group-item-action.active {
  color: #ffffff;
  background-color: #777d6d;
  border-color: #777d6d;
}

.list-group-item-primary10 {
  color: #7f827b;
  background-color: #fcfefa;
}

.list-group-item-primary10.list-group-item-action:hover, .list-group-item-primary10.list-group-item-action:focus {
  color: #7f827b;
  background-color: #effae5;
}

.list-group-item-primary10.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f827b;
  border-color: #7f827b;
}

.list-group-item-tertiary {
  color: #858585;
  background-color: white;
}

.list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-tertiary.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-gray {
  color: #313339;
  background-color: #d2d3d6;
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #313339;
  background-color: #c5c6ca;
}

.list-group-item-gray.list-group-item-action.active {
  color: #ffffff;
  background-color: #313339;
  border-color: #313339;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5;
}

.close:hover {
  color: #000000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.55;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0rem - 1px);
  border-bottom-left-radius: calc(0rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 720px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Avenir-Roman";
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Avenir-Roman";
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0rem - 1px);
  border-top-right-radius: calc(0rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2d3036;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  color: #ffffff;
  text-align: center;
  opacity: 1;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #7fbd27 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #63931e !important;
}

.bg-secondary {
  background-color: #373737 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1e1e1e !important;
}

.bg-success {
  background-color: #4bbc00 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #378900 !important;
}

.bg-info {
  background-color: #8ed7dd !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #67c9d1 !important;
}

.bg-warning {
  background-color: #ffcc00 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cca300 !important;
}

.bg-danger {
  background-color: #ea182f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #be1124 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #2d3036 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #16171a !important;
}

.bg-black {
  background-color: #000000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #ffffff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-primary30 {
  background-color: #e5f1d2 !important;
}

a.bg-primary30:hover, a.bg-primary30:focus,
button.bg-primary30:hover,
button.bg-primary30:focus {
  background-color: #cfe5ab !important;
}

.bg-primary10 {
  background-color: #f5faed !important;
}

a.bg-primary10:hover, a.bg-primary10:focus,
button.bg-primary10:hover,
button.bg-primary10:focus {
  background-color: #dfefc5 !important;
}

.bg-tertiary {
  background-color: #ffffff !important;
}

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray {
  background-color: #5f636e !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #474a53 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #7fbd27 !important;
}

.border-secondary {
  border-color: #373737 !important;
}

.border-success {
  border-color: #4bbc00 !important;
}

.border-info {
  border-color: #8ed7dd !important;
}

.border-warning {
  border-color: #ffcc00 !important;
}

.border-danger {
  border-color: #ea182f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #2d3036 !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-primary30 {
  border-color: #e5f1d2 !important;
}

.border-primary10 {
  border-color: #f5faed !important;
}

.border-tertiary {
  border-color: #ffffff !important;
}

.border-gray {
  border-color: #5f636e !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0rem !important;
}

.rounded {
  border-radius: 0rem !important;
}

.rounded-top {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.rounded-right {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-left {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-lg {
  border-radius: 0rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #7fbd27 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #547e1a !important;
}

.text-secondary {
  color: #373737 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #111111 !important;
}

.text-success {
  color: #4bbc00 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #2c7000 !important;
}

.text-info {
  color: #8ed7dd !important;
}

a.text-info:hover, a.text-info:focus {
  color: #53c2cb !important;
}

.text-warning {
  color: #ffcc00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b38f00 !important;
}

.text-danger {
  color: #ea182f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a60f20 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #2d3036 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0a0b0c !important;
}

.text-black {
  color: #000000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-white {
  color: #ffffff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-primary30 {
  color: #e5f1d2 !important;
}

a.text-primary30:hover, a.text-primary30:focus {
  color: #c3df98 !important;
}

.text-primary10 {
  color: #f5faed !important;
}

a.text-primary10:hover, a.text-primary10:focus {
  color: #d4e9b1 !important;
}

.text-tertiary {
  color: #ffffff !important;
}

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #d9d9d9 !important;
}

.text-gray {
  color: #5f636e !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #3c3e45 !important;
}

.text-body {
  color: #2d3036 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p, h2, .lnk-cookie-modal__modal-headline, h3, .lnk-cookie-modal__manager-headline {
    orphans: 3;
    widows: 3;
  }
  h2, .lnk-cookie-modal__modal-headline, h3, .lnk-cookie-modal__manager-headline {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.lnk-accordion {
  /* prettier-ignore */
}

.lnk-accordion__headlines ~ .accordion {
  margin-top: 40px;
}

.lnk-accordion__element {
  border-top: 1px solid #5f636e;
  hyphens: auto;
}

.lnk-accordion__element:last-child {
  border-bottom: 1px solid #5f636e;
}

.lnk-accordion__element-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0px;
  cursor: pointer;
  margin-bottom: 0;
}

.lnk-accordion__element-head svg {
  transition: transform 300ms;
  margin-bottom: 0.25rem;
  fill: #7fbd27;
}

.lnk-accordion__element-head:not(.collapsed) svg {
  transform: rotateX(180deg);
}

.lnk-accordion__element-head:hover, .lnk-accordion__element-head:not(.collapsed) {
  color: #373737;
}

.lnk-accordion__element-icon {
  margin-right: 4%;
}

.lnk-accordion__element-icon svg {
  width: 36px;
  height: 21px;
}

.lnk-accordion__element-content.show > :last-child,
.lnk-accordion__element-content.collapsing > :last-child {
  padding-bottom: 96px;
}

html {
  font-size: 16px;
}

body {
  max-width: 1920px;
  margin: 0 auto;
}

p {
  margin-bottom: 16px;
}

ul:not([class]) {
  padding-left: 15px;
}

.container {
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 768px) {
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

html.css--editmode .lnk-section {
  padding: 24px 0;
}

html.css--editmode .lnk-component:not(.lnk-puzzle-element) {
  margin: 16px 0;
}

.js-opacity-0 {
  opacity: 0;
  transform: translateY(40px);
}

.js-opacity-1 {
  opacity: 1 !important;
  transform: none;
  transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1) opacity, 2s cubic-bezier(0.075, 0.82, 0.165, 1) transform;
}

.transition-none {
  transition: none !important;
}

*:focus {
  outline: none !important;
}

.btn {
  font-size: 1rem !important;
  line-height: 1.55;
  border-radius: 30px;
  padding: 13px 24px;
}

@media (min-width: 768px) {
  .btn {
    font-size: 1.125rem !important;
  }
}

.btn:focus {
  outline: none !important;
}

.btn.btn-secondary, .btn.btn-primary {
  border: 1px solid #7fbd27 !important;
}

.btn.btn-secondary:focus, .btn.btn-secondary.focus, .btn.btn-secondary:active, .btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active {
  box-shadow: none !important;
}

.btn.btn-secondary:active, .btn.btn-primary:active {
  background-color: #373737 !important;
}

.btn.btn-primary {
  color: #ffffff !important;
  background-color: #7fbd27 !important;
}

.btn.btn-primary:hover {
  color: #7fbd27 !important;
  background-color: #ffffff !important;
}

.btn.btn-secondary {
  border-width: 2px;
  color: #7fbd27;
  background-color: #ffffff;
}

.btn.btn-secondary:hover {
  color: #ffffff;
  background-color: #7fbd27;
}

@media (max-width: 991.98px) {
  .btn {
    width: 100%;
  }
}

.lnk-carousel .modal {
  z-index: 20;
}

.lnk-carousel.lnk-carousel--image-indicators > .carousel,
.lnk-carousel .lnk-lightbox.lnk-lightbox--image-indicators .carousel {
  margin-bottom: calc( 20px + 80px);
}

.lnk-carousel .carousel-control-prev,
.lnk-carousel .carousel-control-next {
  overflow: hidden;
  z-index: 3;
}

.lnk-carousel .carousel-control-prev.lnk-carousel-control--disabled,
.lnk-carousel .carousel-control-next.lnk-carousel-control--disabled {
  pointer-events: none;
}

.lnk-carousel .carousel-control-prev.lnk-carousel-control--disabled svg,
.lnk-carousel .carousel-control-next.lnk-carousel-control--disabled svg {
  fill: #2d3036;
}

.lnk-carousel .carousel-control-next {
  justify-content: flex-end;
  padding-right: 3%;
}

.lnk-carousel .carousel-control-prev {
  justify-content: flex-start;
  padding-left: 3%;
}

.lnk-carousel .carousel:hover .carousel-control-prev,
.lnk-carousel .carousel:hover .carousel-control-next {
  cursor: pointer;
}

.lnk-carousel .carousel:hover .carousel-control-prev .icon-wrapper,
.lnk-carousel .carousel:hover .carousel-control-next .icon-wrapper {
  border: none;
  background-color: transparent;
}

.lnk-carousel .carousel:hover .carousel-control-prev .icon-wrapper svg,
.lnk-carousel .carousel:hover .carousel-control-next .icon-wrapper svg {
  fill: #7fbd27;
}

.lnk-carousel .carousel:hover .carousel-control-prev::before {
  transform: translateX(50%);
}

.lnk-carousel .carousel:hover .carousel-control-next::before {
  transform: translateX(-50%);
}

.lnk-carousel .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  background-color: rgba(45, 48, 54, 0.6);
}

.lnk-carousel .carousel-indicators {
  left: 0;
  bottom: 0;
  margin-bottom: -3px;
  margin-left: 0;
  margin-right: 0;
  z-index: 13;
}

.lnk-carousel .carousel-indicators li {
  width: auto;
  flex-grow: 1;
  border-top: 0;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: #e5f1d2;
  opacity: 1;
}

.lnk-carousel .carousel-indicators li.active {
  background-color: #7fbd27;
  opacity: 1;
}

.lnk-carousel .lnk-carousel-indicators--thumbs {
  justify-content: center;
  top: calc(100% + 20px);
  bottom: 0;
  margin-bottom: 0;
}

.lnk-carousel .lnk-carousel-indicators--thumbs li {
  position: relative;
  height: 80px;
  width: 142px;
  flex-grow: 0;
  margin: 0px 5px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.lnk-carousel .lnk-carousel-indicators--thumbs li img {
  height: inherit;
  width: auto;
}

.lnk-carousel .lnk-carousel-indicators--thumbs li:not(.active) {
  filter: brightness(50%);
}

.lnk-carousel .lnk-carousel-indicators--thumbs li.active::before {
  background-color: #7fbd27;
}

.lnk-carousel .lnk-carousel-indicators--thumbs li::before {
  content: '';
  position: absolute;
  height: 3px;
  left: 0;
  top: 0;
  width: 100%;
  background-color: transparent;
}

.lnk-carousel .carousel-item[data-toggle='modal'] {
  cursor: pointer;
}

.lnk-carousel .carousel-item p {
  font-size: 0.75rem;
  margin: 8px;
}

.lnk-carousel--no-indicators > .carousel {
  margin-bottom: 0;
}

.lnk-carousel--controls-outside > .carousel > .carousel-control-prev {
  left: -22px;
  padding-left: 0;
}

@media (min-width: 768px) {
  .lnk-carousel--controls-outside > .carousel > .carousel-control-prev {
    left: -40px;
  }
}

@media (min-width: 1200px) {
  .lnk-carousel--controls-outside > .carousel > .carousel-control-prev {
    left: -50px;
  }
}

.lnk-carousel--controls-outside > .carousel > .carousel-control-next {
  right: -22px;
  padding-right: 0;
}

@media (min-width: 768px) {
  .lnk-carousel--controls-outside > .carousel > .carousel-control-next {
    right: -40px;
  }
}

@media (min-width: 1200px) {
  .lnk-carousel--controls-outside > .carousel > .carousel-control-next {
    right: -50px;
  }
}

.lnk-carousel--controls-outside > .carousel > .carousel-control-next::before,
.lnk-carousel--controls-outside > .carousel > .carousel-control-prev::before {
  content: none;
}

.lnk-carousel--responsive .carousel-item.active,
.lnk-carousel--responsive .carousel-item-next,
.lnk-carousel--responsive .carousel-item-prev {
  display: flex !important;
}

.lnk-carousel--responsive .carousel-item > * {
  flex: none;
}

.lnk-carousel .icon-wrapper {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: inline-block;
  width: 36px;
  height: 36px;
  position: relative;
}

.lnk-carousel .icon-wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 48px;
  width: 48px;
  fill: #7fbd27;
}

@media (max-width: 767.98px) {
  .lnk-carousel .icon-wrapper svg {
    height: 28px;
    width: 28px;
    fill: #7fbd27;
  }
}

.lnk-carousel--inactive .carousel-item {
  display: block;
  margin: 0;
  margin-bottom: 8px;
}

/* left or forward direction */
/* farthest right hidden item must be abso position for animations */
/* right or prev direction */
.lnk-teaser-carousel .lnk-carousel .carousel-item p {
  font-size: 1rem !important;
  line-height: 1.55;
  margin: 0;
}

@media (min-width: 768px) {
  .lnk-teaser-carousel .lnk-carousel .carousel-item p {
    font-size: 1.125rem !important;
  }
}

@media (max-width: 767.98px) {
  .lnk-teaser-carousel .carousel {
    display: flex;
    flex-wrap: wrap;
  }
  .lnk-teaser-carousel .carousel .carousel-inner {
    margin-bottom: 0;
  }
  .lnk-teaser-carousel .carousel .carousel-control-prev,
  .lnk-teaser-carousel .carousel .carousel-control-next {
    max-width: 50%;
    position: static;
    flex-grow: 1;
    transform: none !important;
  }
  .lnk-teaser-carousel .carousel .carousel-control-prev {
    justify-content: flex-end;
    margin-right: 10px;
  }
  .lnk-teaser-carousel .carousel .carousel-control-next {
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.lnk-cookie {
  background-color: #373737;
  color: #ffffff;
  transition: transform 0.3s;
  transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  padding-top: 40px;
  padding-left: 30px;
  padding-bottom: 35px;
  /* cancel icon */
}

@media (max-width: 575.98px) {
  .lnk-cookie {
    height: 100%;
    overflow-y: scroll;
  }
}

.lnk-cookie p a:not(.btn) {
  color: #fff;
  text-transform: none;
  text-decoration: underline;
}

.lnk-cookie__text {
  padding-right: 102px;
}

.lnk-cookie__buttons {
  margin-top: 24px;
  padding-right: 40px;
}

@media (max-width: 575.98px) {
  .lnk-cookie__buttons {
    flex-direction: column;
  }
}

.lnk-cookie__buttons .btn.btn-primary:hover,
.lnk-cookie__buttons .btn.btn-secondary:hover {
  background: transparent !important;
  border: 1px solid #fff !important;
}

@media (max-width: 575.98px) {
  .lnk-cookie__buttons .btn {
    margin-bottom: 12px !important;
  }
}

.lnk-cookie--active {
  transform: translateY(0);
}

.lnk-cookie__cancel {
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 30px;
}

.lnk-cookie__cancel svg {
  height: 18px;
  width: 18px;
  fill: #fff;
}

.lnk-cookie__manager-headline {
  font-family: "Geogrotesque-Bold";
  font-size: 36px;
  line-height: 47px;
  letter-spacing: 1.2px;
}

.lnk-cookie-modal .modal-body {
  margin-top: 1rem;
}

.lnk-cookie-modal__checkbox {
  vertical-align: middle;
}

.lnk-cookie-modal__checkbox input {
  position: unset;
  margin-left: 0;
  margin-top: 0;
}

.lnk-cta-bar .lnk-component__headline {
  margin-top: 40px;
}

.lnk-cta-bar .lnk-component__headline + .lnk-component__subheadline {
  margin-top: 16px;
  margin-bottom: 32px;
}

.lnk-cta-bar__headlines {
  flex-grow: 1;
}

.lnk-cta-bar.lnk-cta-bar--single-headline .lnk-component__headline:first-child {
  margin-bottom: 40px;
}

.lnk-cta-bar.lnk-cta-bar--single-headline .lnk-component__headline:first-child .lnk-cta-bar__button {
  align-self: center;
  margin-bottom: 0px;
}

.lnk-cta-bar .lnk-cta-bar__button {
  align-self: center;
  margin-left: 20px;
}

@media (max-width: 991.98px) {
  .lnk-cta-bar .d-flex {
    flex-wrap: wrap;
  }
  .lnk-cta-bar .lnk-cta-bar__button {
    align-self: flex-end;
    margin-left: 0px;
    margin-bottom: 32px;
    width: 100%;
  }
}

.lnk-component {
  margin-top: 48px;
}

.lnk-component .lnk-component__headline {
  color: #373737;
}

.lnk-component .lnk-component__subheadline {
  color: #2d3036;
}

@media (min-width: 992px) {
  .lnk-component {
    margin-top: 48px;
  }
}

.lnk-component:first-of-type {
  margin-top: 0;
}

.lnk-component .lnk-component__headline {
  margin-bottom: 24px;
}

.lnk-component .lnk-component__subheadline {
  margin-bottom: 16px;
}

.lnk-component .lnk-component__headline + .lnk-component__subheadline {
  margin-top: -16px;
}

.lnk-contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background-color: #7fbd27;
  height: 100%;
}

.lnk-contact__image {
  width: 100%;
  height: 315px;
}

.lnk-contact__job {
  font-family: "Avenir-Roman";
}

.lnk-contact__src {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  transition: transform 0.4s ease;
}

.lnk-contact__text {
  display: flex;
  flex-direction: column;
  height: calc(100% - 315px);
  width: 100%;
  color: #ffffff;
  padding: 24px;
}

.lnk-contact__bottom {
  margin-top: auto;
  display: block;
  width: 100%;
  color: #ffffff;
  padding-top: 32px;
}

.lnk-contact a {
  color: #ffffff;
  text-decoration: underline;
}

.lnk-contact a:hover {
  font-family: "Avenir-Roman";
}

.lnk-contact.lnk-contact--no-image .lnk-contact__text {
  height: 100%;
}

.lnk-facts__box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #7fbd27;
  color: #ffffff;
  padding: 16px;
  hyphens: auto;
}

.lnk-facts__secondary-top, .lnk-facts__secondary-bottom {
  min-height: 22px;
}

@media (min-width: 992px) {
  .lnk-facts__secondary-top, .lnk-facts__secondary-bottom {
    min-height: 22px;
  }
}

@media (min-width: 1200px) {
  .lnk-facts__secondary-top, .lnk-facts__secondary-bottom {
    min-height: 28px;
  }
}

.lnk-facts__secondary-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.lnk-facts__row {
  margin-bottom: -24px;
}

@media (min-width: 992px) {
  .lnk-facts__row {
    margin-bottom: 0;
  }
}

.lnk-facts__col {
  padding-bottom: 24px;
}

@media (min-width: 992px) {
  .lnk-facts__col {
    padding-bottom: 0;
  }
}

.lnk-facts__primary {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lnk-footer {
  background-color: #373737;
  color: #ffffff;
  padding: 32px 0px;
  max-width: 1920px;
  margin: 0 auto;
}

.lnk-footer hr {
  border-color: #ffffff;
}

.lnk-footer a {
  color: #ffffff;
}

.lnk-footer a:hover {
  color: #7fbd27;
}

.lnk-footer__social-links {
  order: 5;
  display: flex;
  flex-wrap: wrap;
}

.lnk-footer__social-links .lnk-social-links--colored div {
  height: 40px;
  width: 40px !important;
  border-radius: 50% 50%;
  margin-right: 1rem;
}

.lnk-footer__social-links .lnk-social-links div {
  width: 20px;
  height: 40px;
  border-radius: 50% 50%;
  margin-right: 1rem;
}

.lnk-footer__link-list ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.lnk-footer__link-list ul li {
  margin-left: 32px;
}

.lnk-footer__link-list ul li a {
  font-size: 16px;
  line-height: 24px;
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

.lnk-footer__notice {
  padding-top: 16px;
}

.lnk-footer__notice p {
  font-size: 12px;
  font-family: "Avenir-Roman";
  line-height: inherit;
}

.lnk-footer__notice a {
  text-decoration: underline;
}

.lnk-footer__copyright p {
  font-size: 18px;
  line-height: 28px;
  font-family: "Avenir-Roman";
}

@media (max-width: 991.98px) {
  .lnk-footer__link-list ul {
    display: block;
  }
  .lnk-footer__link-list ul li {
    margin-left: 0px;
    margin-bottom: 16px;
  }
}

@media (min-width: 992px) {
  .lnk-footer__notice {
    width: calc(10 / 12 * 100%);
  }
}

.custom-control-label {
  cursor: pointer;
}

.custom-control-label::after {
  background: no-repeat 50% / 80% 80%;
}

.custom-select {
  -moz-appearance: none;
}

.form-control:focus,
.custom-select:focus {
  font-family: "Avenir-Roman";
  background-color: #e5f1d2;
}

.form-control:hover,
.custom-select:hover {
  background-color: #e5f1d2;
}

.custom-checkbox .custom-control-input {
  left: 0;
}

.custom-checkbox:not(:checked):hover .custom-control-input:not(:checked) ~ .custom-control-label::before {
  background-color: #e5f1d2;
}

form .geburtsort {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

/* stylelint-disable selector-type-no-unknown */
_:-ms-fullscreen,
:root .lnk-header {
  position: relative;
}

/* stylelint-enable */
.lnk-header {
  max-width: 1920px;
  margin: 0 auto;
  height: 80px;
  border-top: 3px solid #e5f1d2;
  border-bottom: 2px #eee solid;
  position: sticky;
  top: 0;
  z-index: 14;
  background-color: #ffffff;
}

.lnk-header > div {
  height: 100%;
}

.lnk-header__logo {
  margin-right: 16px;
  height: 100%;
}

@media (min-width: 1200px) {
  .lnk-header__logo {
    margin-right: 48px;
  }
}

.lnk-header__navigation {
  flex-grow: 1;
}

@media (min-width: 992px) {
  .lnk-header__navigation {
    transform: translateY(-1px);
  }
}

.lnk-header__language {
  padding: 0 8px;
  display: flex;
  margin-bottom: 3px;
}

.lnk-header__language a {
  font-family: "Geogrotesque-Bold";
  position: relative;
}

.lnk-header__language a:not(.active) {
  color: #2d3036;
}

.lnk-header__language a:not(:last-child) {
  margin-right: 10px;
}

.lnk-header__language a:not(:last-child)::after {
  position: absolute;
  right: -5px;
  transform: translateX(50%);
  content: '|';
  color: #2d3036;
}

.lnk-header__search {
  padding: 0 8px;
  cursor: pointer;
}

.lnk-header__search svg {
  width: 22px;
  height: 22px;
}

.lnk-header__mobile-opener .lnk-header-navbar-toggler:focus {
  outline: none !important;
}

.lnk-header__mobile-opener .lnk-header-navbar-toggler .navbar-toggler-icon {
  height: auto;
}

.is-header-icon svg {
  fill: #2d3036;
  stroke: #2d3036;
}

.is-header-icon--inactive {
  pointer-events: none;
  opacity: 0.3;
}

.is-header-icon--inactive svg {
  fill: #5f636e;
  stroke: #5f636e;
}

.is-header-icon--active svg {
  fill: #7fbd27;
  stroke: #7fbd27;
}

@media (max-width: 991.98px) {
  .lnk-header {
    height: 60px;
  }
}

.lnk-interview__quote {
  z-index: 1;
  position: relative;
  padding-bottom: 8px;
}

.lnk-interview__quote .lnk-interview__text {
  min-height: 56px;
  padding: 8px 8px;
}

@media (min-width: 992px) {
  .lnk-interview__quote .lnk-interview__text {
    min-height: 75px;
    padding: 16px 24px;
  }
}

.lnk-interview__quote .lnk-interview__text p {
  font-family: "Avenir-Roman";
  font-size: 1.25rem !important;
  line-height: 1.55;
}

@media (min-width: 768px) {
  .lnk-interview__quote .lnk-interview__text p {
    font-size: 1.625rem !important;
  }
}

.lnk-interview__mark-start svg,
.lnk-interview__mark-end svg {
  position: absolute;
  width: 60px;
  height: auto;
  fill: #373737;
  opacity: 0.3;
  z-index: -1;
}

@media (min-width: 992px) {
  .lnk-interview__mark-start svg,
  .lnk-interview__mark-end svg {
    width: 80px;
  }
}

.lnk-interview__mark-start svg {
  bottom: 8px;
  left: 0;
}

.lnk-interview__mark-end svg {
  top: 0;
  right: 0;
}

.lnk-interview__author {
  padding-top: 8px;
  display: block;
  width: 100%;
  border-top: 1px #2d3036 solid;
  color: 1px #2d3036 solid;
}

.lnk-interview__author p {
  font-size: 0.875rem !important;
  line-height: 1.55;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .lnk-interview__author p {
    font-size: 1rem !important;
  }
}

.lnk-interview__img {
  position: relative;
  width: 100%;
  padding-bottom: 24px;
}

@media (min-width: 992px) {
  .lnk-interview__img {
    padding-bottom: 0;
  }
}

.lnk-interview__img img {
  width: 100%;
  height: auto;
}

.lnk-interview__header {
  margin-bottom: 40px;
}

.lnk-interview__caption {
  color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  background-color: rgba(45, 48, 54, 0.6);
  text-align: center;
}

.lnk-interview__caption p {
  font-size: 0.75rem;
  margin: 8px;
}

.lnk-interview__element-head {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 24px 0 16px 0;
  justify-content: space-between;
}

.lnk-interview__element-head:hover {
  color: #7fbd27;
}

.lnk-interview__element-head.collapsed .minus {
  display: none;
}

.lnk-interview__element-head.collapsed .plus {
  display: block;
}

.lnk-interview__element-head:not(.collapsed) .minus {
  display: block;
}

.lnk-interview__element-head:not(.collapsed) .plus {
  display: none;
}

.lnk-interview__icon {
  width: 38px;
  height: auto;
  float: right;
  margin-bottom: 8px;
}

.lnk-interview__icon svg {
  fill: #7fbd27;
}

.lnk-interview__element {
  border-top: 1px solid #5f636e;
}

.lnk-interview__element-content > div {
  padding-bottom: 24px;
}

.modal-backdrop {
  z-index: 15;
}

.modal-open .lnk-text-media__image {
  z-index: auto;
}

.lnk-lightbox {
  color: #2d3036;
  padding-right: 15px;
  padding-left: 15px;
}

.lnk-lightbox .modal-body {
  padding-top: 0;
}

@media (max-width: 1199.98px) {
  .lnk-lightbox .modal-body .vjs-big-play-button {
    width: 100px !important;
    height: 100px !important;
  }
}

.lnk-lightbox .close svg {
  width: 24px;
  fill: #2d3036;
}

.lnk-lightbox .lnk-video.video-js {
  background-color: #ffffff !important;
}

@media (min-width: 992px) {
  .lnk-lightbox--ajax-link .lnk-section > .container {
    max-width: calc(100% - 100px);
  }
  .lnk-lightbox--ajax-link .modal-xl {
    max-width: calc(960px + 20px);
  }
  .lnk-lightbox--ajax-link section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .lnk-lightbox--ajax-link .modal-xl {
    max-width: calc(1140px + 30px);
  }
  .lnk-lightbox--ajax-link section {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.lnk-lightbox--ajax-link .modal-content {
  border: 0;
}

.lnk-lightbox--ajax-link .modal-header {
  padding: 0;
  border-bottom: 0;
  position: sticky;
  top: 1rem;
  z-index: 25;
  right: 1rem;
}

.lnk-lightbox--ajax-link .modal-header .close {
  position: absolute;
  z-index: 23;
  right: 1rem;
  top: 1rem;
}

.lnk-lightbox--ajax-link .modal-header .close svg {
  fill: #7fbd27;
}

.lnk-lightbox--ajax-link .modal-body {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.lnk-logo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lnk-logo a {
  display: block;
  height: 100%;
}

.lnk-logo img {
  height: 100%;
  width: auto;
  max-height: 80px;
}

.navbar-light .dropdown-menu {
  border: none;
  border-top: 3px solid #7fbd27;
}

.navbar-light .dropdown-menu .dropdown-item {
  font-family: "Avenir-Roman";
}

.navbar-light .navbar-nav {
  font-family: "Geogrotesque-Bold";
  font-size: 16px;
  text-transform: uppercase;
  background-color: #ffffff;
}

.navbar-light .navbar-nav .lnk-nav-item-link-container {
  position: relative;
}

.navbar-light .navbar-nav .nav-item.active .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #7fbd27 !important;
}

nav.navbar .lnk-nav-item-link-container {
  display: flex;
  overflow-x: hidden;
}

nav.navbar .lnk-nav-item-link-container .nav-link {
  flex-grow: 1;
}

nav.navbar .lnk-nav-item-link-container .nav-link:focus {
  color: #2d3036;
}

@media (min-width: 992px) {
  nav.navbar .nav-item {
    position: relative;
  }
  nav.navbar li.lnk-dropdown:hover .dropdown-menu {
    display: block;
    transform: translateY(-2px);
  }
  nav.navbar .lnk-dropdown__arrow-for-small {
    display: none;
  }
  nav.navbar .lnk-nav-item-link-container {
    height: 80px;
    align-items: center;
    border-bottom: 2px #eee solid;
  }
  nav.navbar .lnk-nav-item-link-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0px;
    left: 0;
    background-color: #e5f1d2;
  }
  nav.navbar .lnk-nav-item-link-container:hover::before, nav.navbar .lnk-nav-item-link-container:focus::before {
    background: #7fbd27;
  }
  nav.navbar .nav-item.active .lnk-nav-item-link-container::before {
    background: #7fbd27;
  }
  nav.navbar .navbar-brand {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  nav.navbar.lnk-top-navigation {
    width: 100%;
    overflow-y: scroll;
    background-color: #ffffff;
    display: block;
    position: absolute !important;
    left: 0;
    top: 57px;
  }
  nav.navbar.lnk-top-navigation ul.navbar-nav > .nav-item .nav-link {
    padding-left: 8px;
  }
  nav.navbar .lnk-dropdown__arrow-for-small {
    padding: 0px 10px 0px 30px;
    display: flex;
    align-items: center;
  }
  nav.navbar .lnk-dropdown__arrow-for-small svg {
    width: 9px;
    height: 15px;
    pointer-events: none;
    transition: transform 0.3s;
  }
  nav.navbar .lnk-dropdown__arrow-for-small.active svg {
    transform: rotate(90deg);
  }
  nav.navbar.lnk-top-navigation--open {
    margin-top: 57px;
    min-height: calc( 100vh - 57px);
    overflow-y: scroll;
    background-color: #ffffff;
    display: block;
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  nav.navbar.lnk-top-navigation--open .lnk-opened-navbar-toggler {
    float: right;
  }
  nav.navbar.lnk-top-navigation--open .navbar-collapse {
    padding-top: 5px;
    display: block !important;
  }
  nav.navbar.lnk-top-navigation--open .navbar-collapse.collapsing {
    transition: none;
    display: none;
  }
  nav.navbar li.lnk-dropdown a.nav-link::after {
    display: none;
  }
  nav.navbar:not(.lnk-top-navigation--open) .lnk-opened-navbar-toggler,
  nav.navbar:not(.lnk-top-navigation--open) .navbar-brand {
    display: none !important;
  }
}

nav.navbar .navbar-brand {
  margin-left: 8px;
  margin-top: 8px;
}

nav.navbar .lnk-opened-navbar-toggler {
  margin-right: 8px;
  margin-top: 8px;
}

nav.navbar .lnk-opened-navbar-toggler svg {
  height: 30px;
}

@media (min-width: 992px) {
  .lnk-header-navbar-toggler {
    display: none;
  }
}

.one-for-two {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .one-for-two {
    width: 100%;
  }
}

.two-for-one {
  width: 100%;
  height: 50%;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .two-for-one {
    flex-wrap: wrap;
  }
}

.one-for-two::before,
.two-for-one::before {
  font-size: 2em;
  font-weight: bold;
  margin-right: 20px;
}

.lnk-puzzle [data-content='1'] .lnk-puzzle-element--empty {
  background-color: #272727;
}

.lnk-puzzle [data-content='2'] .lnk-puzzle-element--empty {
  background-color: #5d5d5d;
}

.lnk-puzzle [data-content='3'] .lnk-puzzle-element--empty {
  background-color: #999999;
}

.lnk-puzzle [data-content='4'] .lnk-puzzle-element--empty {
  background-color: #343434;
}

.lnk-puzzle [data-content='5'] .lnk-puzzle-element--empty {
  background-color: #676767;
}

.lnk-puzzle [data-content='6'] .lnk-puzzle-element--empty {
  background-color: #b7b7b7;
}

.lnk-puzzle [data-content='7'] .lnk-puzzle-element--empty {
  background-color: #3c3c3c;
}

.lnk-puzzle [data-content='8'] .lnk-puzzle-element--empty {
  background-color: #7b7b7b;
}

.lnk-puzzle [data-content='9'] .lnk-puzzle-element--empty {
  background-color: #cccccc;
}

.lnk-puzzle [data-content='10'] .lnk-puzzle-element--empty {
  background-color: #484848;
}

.lnk-puzzle [data-content='11'] .lnk-puzzle-element--empty {
  background-color: #858585;
}

.lnk-puzzle [data-content='12'] .lnk-puzzle-element--empty {
  background-color: #525252;
}

.lnk-puzzle--inverted .lnk-puzzle__elements-container.d-flex > :nth-child(1),
.lnk-puzzle--inverted .lnk-puzzle__elements-container .one-for-two.d-flex > :nth-child(1),
.lnk-puzzle--inverted .lnk-puzzle__elements-container .two-for-one.d-flex > :nth-child(1) {
  order: 1;
}

.lnk-puzzle__elements-container {
  width: 100%;
}

@media (min-width: 992px) {
  .lnk-puzzle__elements-container {
    height: 80vh;
    min-height: 600px;
  }
}

.lnk-puzzle .d-flex:not(.two-for-one) {
  flex-wrap: wrap;
}

@media (max-width: 991.98px) {
  [data-content] {
    display: flex;
    flex-grow: 1;
    height: auto;
  }
  [data-content] .lnk-puzzle-element {
    flex-grow: 1;
    display: flex;
  }
  [data-content] .lnk-puzzle-element > .d-flex,
  [data-content] .lnk-puzzle-element > a {
    height: auto;
    width: 100%;
  }
}

.lnk-puzzle-element--empty {
  background-image: url("data:image/svg+xml;utf8,%3Csvg width='46' height='64' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ddd' fill-rule='evenodd'%3E%3Cpath d='M0 40h45.4194v5.1613H0zM0 49h45.4194v5.1613H0zM0 58h32v5.1613H0zM45.4194 0v32H0V0h45.4194zm-4.542 4.5714H4.542v22.8572h36.3355V4.5714z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: auto auto !important;
}

.lnk-puzzle-element {
  hyphens: auto;
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.lnk-puzzle-element > .d-flex,
.lnk-puzzle-element > a {
  height: 100%;
  padding: 10px;
}

@media (max-width: 991.98px) {
  .lnk-puzzle-element {
    min-height: 275px;
  }
}

.lnk-puzzle-element a {
  color: inherit;
  width: 100%;
}

.lnk-puzzle-element a:hover {
  color: inherit;
  text-decoration: inherit;
}

.lnk-puzzle-element__headlines {
  width: 100%;
}

.lnk-puzzle-element__link-indicator {
  width: 100%;
  align-self: flex-end;
  text-align: right;
  margin: 2px;
}

.lnk-puzzle-element__link-indicator svg {
  fill: #ffffff;
  width: 28px;
  height: 30px;
}

.lnk-puzzle-element--text-top .lnk-puzzle-element__headlines {
  align-self: flex-start;
}

.lnk-puzzle-element--text-bottom .lnk-puzzle-element__headlines {
  align-self: flex-end;
}

.lnk-puzzle-element--text-bottom .lnk-puzzle-element__link-indicator {
  align-self: flex-start;
  order: -1;
}

.lnk-puzzle-element--text-left .lnk-puzzle-element__headlines {
  text-align: left;
}

.lnk-puzzle-element--text-right .lnk-puzzle-element__headlines {
  text-align: right;
}

.lnk-puzzle-element--text-dark .lnk-component__headline,
.lnk-puzzle-element--text-dark .lnk-component__subheadline {
  color: #7fbd27;
}

.lnk-puzzle-element--text-dark .lnk-puzzle-element__link-indicator svg {
  fill: #7fbd27;
}

.lnk-puzzle-element--text-light .lnk-component__headline,
.lnk-puzzle-element--text-light .lnk-component__subheadline {
  color: #ffffff;
}

.lnk-puzzle-element--image {
  z-index: 1;
  transition: all 0.4s;
}

.lnk-puzzle-element--image *:not(.lnk-puzzle-element__image) {
  z-index: 5;
}

.lnk-puzzle-element--image .lnk-puzzle-element__image {
  transition: all 0.4s;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.lnk-puzzle-element--image .lnk-puzzle-element__image::before {
  position: absolute;
  overflow-y: hidden;
  transition: all 0.4s;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #373737;
}

.lnk-puzzle-element--image.lnk-puzzle-element--has-link:hover .lnk-puzzle-element__image {
  transform: scale(1.05);
}

.lnk-puzzle-element--image.lnk-puzzle-element--has-link:hover .lnk-puzzle-element__image::before {
  background-color: #373737;
  opacity: 0.4;
}

.lnk-puzzle-element--image.lnk-puzzle-element--image-opacity-none.lnk-puzzle-element--has-link:hover .lnk-puzzle-element__image::before {
  opacity: 0;
}

.lnk-puzzle-element--image.lnk-puzzle-element--image-opacity-light.lnk-puzzle-element--has-link:hover .lnk-puzzle-element__image::before {
  opacity: 0.2;
}

.lnk-puzzle-element--image.lnk-puzzle-element--image-opacity-medium.lnk-puzzle-element--has-link:hover .lnk-puzzle-element__image::before {
  opacity: 0.5;
}

.lnk-puzzle-element--image.lnk-puzzle-element--image-opacity-high.lnk-puzzle-element--has-link:hover .lnk-puzzle-element__image::before {
  opacity: 0.7;
}

.lnk-puzzle-element--image-opacity-none .lnk-puzzle-element__image::before {
  opacity: 0;
}

.lnk-puzzle-element--image-opacity-light .lnk-puzzle-element__image::before {
  opacity: 0.2;
}

.lnk-puzzle-element--image-opacity-medium .lnk-puzzle-element__image::before {
  opacity: 0.5;
}

.lnk-puzzle-element--image-opacity-high .lnk-puzzle-element__image::before {
  opacity: 0.7;
}

.lnk-puzzle-element .mgnlEditorBar {
  position: absolute !important;
}

.lnk-puzzle-element .mgnlEditorBar:not(.focus):not(:hover) {
  opacity: 0.5;
}

.lnk-quotation__quote {
  z-index: 1;
  position: relative;
  padding-bottom: 8px;
}

.lnk-quotation__quote .lnk-quotation__text {
  min-height: 56px;
  padding: 8px 8px;
}

@media (min-width: 992px) {
  .lnk-quotation__quote .lnk-quotation__text {
    min-height: 75px;
    padding: 16px 24px;
  }
}

.lnk-quotation__quote .lnk-quotation__text p {
  font-family: "Avenir-Roman";
  font-size: 1.25rem !important;
  line-height: 1.55;
}

@media (min-width: 768px) {
  .lnk-quotation__quote .lnk-quotation__text p {
    font-size: 1.625rem !important;
  }
}

.lnk-quotation__mark-start svg,
.lnk-quotation__mark-end svg {
  position: absolute;
  width: 60px;
  height: auto;
  fill: #373737;
  opacity: 0.3;
  z-index: -1;
}

@media (min-width: 992px) {
  .lnk-quotation__mark-start svg,
  .lnk-quotation__mark-end svg {
    width: 80px;
  }
}

.lnk-quotation__mark-start svg {
  bottom: 8px;
  left: 0;
}

.lnk-quotation__mark-end svg {
  top: 0;
  right: 0;
}

.lnk-quotation__author {
  padding-top: 8px;
  display: block;
  width: 100%;
  border-top: 1px #2d3036 solid;
  color: #2d3036;
}

.lnk-quotation__author p {
  font-size: 0.875rem !important;
  line-height: 1.55;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .lnk-quotation__author p {
    font-size: 1rem !important;
  }
}

.lnk-quotation__img {
  width: 100%;
  padding-bottom: 8px;
}

.lnk-quotation__img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.lnk-quotation .carousel-inner {
  display: flex;
  align-items: center;
  height: 100%;
}

.lnk-quotation .carousel-item:not(.active),
.lnk-quotation .carousel-item.carousel-item-left,
.lnk-quotation .carousel-item.carousel-item-right {
  opacity: 0;
  visibility: hidden;
}

.lnk-quotation .carousel-item.active {
  opacity: 1;
}

.lnk-quotation .carousel-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.lnk-quotation .carousel-item > *:last-child {
  margin-bottom: auto;
}

.lnk-quotation .lnk-quotation__img + * {
  margin-top: auto;
}

.lnk-scrollto {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 15;
  opacity: 0.5;
  transition: opacity 0.3s;
  cursor: pointer;
}

.lnk-scrollto:hover {
  opacity: 1;
}

@media (max-width: 991.98px) {
  .lnk-scrollto {
    transform: scale(0.7);
    right: 4px;
    opacity: 1;
    bottom: 4px;
  }
}

.lnk-search {
  padding-right: 0;
}

.lnk-search .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.lnk-search .modal-content {
  background-color: #2d3036;
  border: 0;
}

.lnk-search .modal-header {
  border-bottom: 0;
}

.lnk-search input[type='text'] {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #7fbd27;
  padding-left: 0;
  color: #ffffff;
  font-family: "Avenir-Roman";
}

.lnk-search input[type='text']:focus {
  box-shadow: none;
}

.lnk-search h2, .lnk-search .lnk-cookie-modal__modal-headline {
  color: #7fbd27;
}

.lnk-search .close {
  margin: 0;
  padding: 0;
  opacity: 1;
}

.lnk-search .close svg {
  width: 40px;
  height: 40px;
  fill: #ffffff;
}

@media (max-width: 991.98px) {
  .lnk-search .close svg {
    width: 20px;
    height: 20px;
  }
}

.lnk-search .modal-footer {
  border-top: 0;
  display: block;
  overflow-y: scroll;
  background-color: #ffffff;
}

.lnk-search .modal-footer a {
  display: block;
}

.lnk-search .modal-footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.lnk-search .modal-footer li {
  border-top: solid 1px #5f636e;
  padding-top: 23px;
  padding-bottom: 23px;
}

.lnk-search .modal-footer li:last-child {
  border-bottom: solid 1px #5f636e;
}

.lnk-search .modal-footer svg {
  fill: #7fbd27;
  width: 32px;
  height: 32px;
  margin-right: 44px;
  margin-left: 44px;
}

@media (max-width: 991.98px) {
  .lnk-search .modal-footer svg {
    margin-right: 0;
  }
}

.lnk-search .modal-footer .lnk-search__results-headline {
  color: #373737;
}

.lnk-search .modal-footer .lnk-search__results-text {
  color: #2d3036;
}

.lnk-section {
  position: relative;
  padding: 56px 0;
}

.lnk-section .lnk-section__headline {
  color: #373737;
}

.lnk-section .lnk-section__subheadline {
  color: #2d3036;
}

@media (min-width: 992px) {
  .lnk-section {
    padding: 96px 0;
  }
}

.lnk-section .lnk-section__headline {
  margin-bottom: 24px;
}

.lnk-section .lnk-section__subheadline {
  margin-bottom: 24px;
}

.lnk-section .lnk-section__headline + .lnk-section__subheadline {
  margin-top: -16px;
}

@media (min-width: 992px) {
  .lnk-section .lnk-section__headline {
    margin-bottom: 48px;
  }
  .lnk-section .lnk-section__subheadline {
    margin-bottom: 48px;
  }
  .lnk-section .lnk-section__headline + .lnk-section__subheadline {
    margin-top: -32px;
  }
}

.lnk-section--highlight + .lnk-section--highlight {
  padding-top: 0;
}

.lnk-section--blog + .lnk-section--blog {
  padding-top: 0;
}

/* prettier-ignore */
.lnk-section:not(.lnk-section--highlight):not(.lnk-section--image):not(.lnk-section--blog) + .lnk-section:not(.lnk-section--highlight):not(.lnk-section--image):not(.lnk-section--blog) {
  padding-top: 0;
}

.lnk-section .container > *:last-child {
  margin-bottom: 0;
}

.lnk-sitemap ul {
  padding: 0;
  list-style-type: none;
}

.lnk-sitemap [data-level='0'] {
  border-top: solid 1px #5f636e;
  padding-left: 74px;
  padding-top: 24px;
  padding-bottom: 60px;
}

.lnk-sitemap [data-level='0'] > ul {
  margin-top: 43px;
}

.lnk-sitemap [data-level='0']:last-child {
  border-bottom: solid 1px #5f636e;
}

.lnk-sitemap [data-level='1'] {
  padding-left: 24px;
}

.lnk-sitemap [data-level='1']:not(:last-child) {
  margin-bottom: 30px;
}

.lnk-sitemap [data-link-level='0'] {
  margin-bottom: 43px;
  font-family: "Geogrotesque-Bold";
  color: #373737;
}

.lnk-sitemap [data-link-level='1'] {
  font-family: "Geogrotesque-Bold";
  color: #2d3036;
}

.lnk-structure-container {
  /* prettier-ignore */
}

.lnk-structure-container__element {
  min-height: 100px;
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  .lnk-structure-container__element {
    margin-bottom: 0;
  }
}

.lnk-structure-container > .lnk-text-media .lnk-text-media__text,
.lnk-structure-container > .lnk-text-media .lnk-text-media__headlines {
  margin-left: 0;
  max-width: 100%;
}

.lnk-structure-container > .lnk-text-media .col-lg-4 .lnk-text-media__image,
.lnk-structure-container > .lnk-text-media .col-lg-6 .lnk-text-media__image {
  float: none;
  max-width: 100%;
}

.lnk-structure-container .lnk-text-media__image .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel {
  margin-bottom: calc(20px + 20px + 60px);
}

.lnk-structure-container .lnk-text-media__image .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel > .lnk-carousel-indicators--thumbs li {
  height: 60px;
}

.lnk-structure-container .col-lg-8 .lnk-text-media__image.col-lg-4 {
  max-width: 50%;
}

.lnk-stage__edit-mode-note {
  left: 0;
  bottom: 0;
}

.lnk-stage__img {
  width: 100%;
  height: auto;
  display: none;
}

.lnk-stage--fix-height .lnk-stage__background {
  display: flex;
  height: calc( 60vh - 60px - 3px);
}

@media (min-width: 992px) {
  .lnk-stage--fix-height .lnk-stage__background {
    display: flex;
    height: 610px;
  }
}

@media (min-width: 992px) {
  .lnk-stage--fix-height .lnk-stage__background.lnk-stage--no-image {
    height: auto;
    padding: 32px 0;
  }
}

.lnk-stage--forced-16-9 {
  max-height: calc(100vh - 80px);
}

.lnk-stage .lnk-stage__background.lnk-stage--no-image {
  background-color: #373737;
}

.lnk-stage.lnk-video .video-js {
  font-size: 15px;
  position: static;
  width: 100%;
}

.lnk-stage.lnk-video .video-js:not(.vjs-16-9) {
  height: calc( 100vh - 80px - 3px);
}

.lnk-stage.lnk-video .video-js:not(.vjs-16-9) video {
  height: auto;
}

.lnk-stage.lnk-video .video-js.vjs-16-9 {
  height: auto;
}

@media (min-width: 768px) {
  .lnk-stage.lnk-video .video-js .vjs-control-bar {
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.lnk-stage.lnk-video .video-js video {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.lnk-stage .video-js + .lnk-stage__background {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.lnk-stage__background {
  position: relative;
  height: calc( 100vh - 60px - 3px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.lnk-stage__background::before {
  position: absolute;
  overflow-y: hidden;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(45, 48, 54, 0.15);
  background: linear-gradient(to bottom, rgba(45, 48, 54, 0.15) 0%, rgba(45, 48, 54, 0) 22%, rgba(45, 48, 54, 0.2) 40%, rgba(45, 48, 54, 0.5) 60%, rgba(45, 48, 54, 0.5) 100%);
}

@media (min-width: 992px) {
  .lnk-stage__background::before {
    background: rgba(45, 48, 54, 0.6);
    background: linear-gradient(to right, rgba(45, 48, 54, 0.6) 0%, rgba(45, 48, 54, 0.6) 40%, rgba(45, 48, 54, 0.2) 60%, rgba(45, 48, 54, 0) 75%, rgba(45, 48, 54, 0.4) 100%);
  }
}

.lnk-stage__background::after {
  position: absolute;
  overflow-y: hidden;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(55, 55, 55, 0.25);
  background: linear-gradient(to bottom, rgba(55, 55, 55, 0.25) 0%, rgba(55, 55, 55, 0) 19%, rgba(55, 55, 55, 0) 72%, rgba(55, 55, 55, 0.25) 100%);
}

@media (min-width: 992px) {
  .lnk-stage__background {
    height: calc( 100vh - 80px - 3px);
    align-items: center;
  }
}

.lnk-stage__link:hover {
  text-decoration: none;
}

.lnk-stage__link:hover svg {
  fill: #7fbd27;
  transform: translateX(32px);
}

.lnk-stage__text {
  width: 100%;
  margin-bottom: 24px;
}

@media (min-width: 992px) {
  .lnk-stage__text {
    margin-bottom: 8px;
  }
}

.lnk-stage__text .section-scroll-button-icon {
  position: absolute;
  border: 0;
  background-color: transparent;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  height: 50px;
  width: 50px;
}

.lnk-stage__text .section-scroll-button-icon svg {
  fill: #7fbd27;
}

.lnk-stage__text .section-scroll-button-text {
  margin-top: 44px;
}

.lnk-stage__headline {
  color: #ffffff;
}

.lnk-stage__subheadline,
.lnk-stage p {
  color: #ffffff;
}

.lnk-stage__arrow svg {
  transition: 0.3s;
  fill: #ffffff;
  width: 24px;
  height: 24px;
}

@media (min-width: 992px) {
  .lnk-stage__arrow svg {
    width: 40px;
    height: 40px;
  }
}

.lnk-stage__box {
  background-color: #373737;
  padding: 32px 0 28px 0;
  color: #ffffff;
}

.lnk-stage__box .lnk-stage__headline {
  color: #ffffff;
}

@media (min-width: 992px) {
  .lnk-stage__box h1,
  .lnk-stage__box h2,
  .lnk-stage__box .lnk-cookie-modal__modal-headline {
    display: none;
  }
}

@media (min-width: 992px) {
  .lnk-stage--text-right {
    text-align: right;
  }
}

@media (min-width: 992px) {
  .lnk-stage--text-right .lnk-stage__background::before {
    background: rgba(45, 48, 54, 0.4);
    background: linear-gradient(to right, rgba(45, 48, 54, 0.4) 0%, rgba(45, 48, 54, 0) 25%, rgba(45, 48, 54, 0.2) 40%, rgba(45, 48, 54, 0.6) 60%, rgba(45, 48, 54, 0.6) 100%);
  }
}

.lnk-stage--text-right .lnk-stage__arrow svg {
  margin-right: 32px;
}

.lnk-stage--text-right .lnk-stage__box {
  text-align: left;
}

.lnk-carousel .carousel-item .lnk-stage__intro p {
  font-family: "Avenir-Black";
  margin: 8px 0;
  font-size: 1rem !important;
  line-height: 1.55;
}

@media (min-width: 768px) {
  .lnk-carousel .carousel-item .lnk-stage__intro p {
    font-size: 1.125rem !important;
  }
}

.lnk-carousel--stage {
  margin: 0 auto;
}

.lnk-carousel--stage .carousel-control-prev,
.lnk-carousel--stage .carousel-control-next {
  width: 15%;
}

.lnk-carousel--stage .carousel-control-prev .icon-wrapper,
.lnk-carousel--stage .carousel-control-next .icon-wrapper {
  background-color: transparent;
}

@media (max-width: 991.98px) {
  .lnk-table {
    overflow-x: scroll;
    padding-left: 2px;
    padding-right: 2px;
  }
}

table {
  border: none;
  border-spacing: 0px 20px;
  border-collapse: separate;
}

table tr {
  outline: solid 2px #7fbd27;
}

table td,
table th {
  padding: 11px 0px 11px 20px;
}

table td:last-child,
table th:last-child {
  padding-right: 20px;
}

table th {
  background-color: #7fbd27;
  border: none;
  font-family: "Avenir-Black";
  font-weight: normal;
  vertical-align: top;
}

table td {
  border: none;
  background-color: #e5f1d2;
}

.lnk-teaser {
  position: relative;
}

.lnk-teaser a:hover .lnk-teaser__src {
  transform: scale(1.1);
}

.lnk-teaser:hover .lnk-teaser__subheadline,
.lnk-teaser:hover p {
  max-height: 300px;
  transition: ease max-height 0.4s;
}

.lnk-teaser--no-image .lnk-teaser__image {
  background-color: #7fbd27;
}

.lnk-teaser--no-text .lnk-teaser__image {
  padding-bottom: 0;
}

.lnk-teaser--no-text {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lnk-teaser__image {
  width: 100%;
  height: 485px;
  overflow: hidden;
}

.lnk-teaser__image--no-text {
  height: auto;
}

.lnk-teaser--video .lnk-teaser__image .lnk-teaser__src {
  position: relative;
  cursor: pointer;
}

.lnk-teaser--video .lnk-teaser__image .lnk-teaser__src::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 58 58' width='58' height='58' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0a29 29 0 110 58 29 29 0 010-58zm0 6a23 23 0 100 46 23 23 0 000-46zm-8 12l22 11-22 11V18z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
  display: inline-block;
  width: 58px;
  height: 58px;
  background-repeat: no-repeat !important;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-teaser--video:hover .lnk-teaser__image .lnk-teaser__src::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 58 58' width='58' height='58' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0a29 29 0 110 58 29 29 0 010-58zm0 6a23 23 0 100 46 23 23 0 000-46zm-8 12l22 11-22 11V18z' fill='%237fbd27' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
}

.lnk-teaser__src {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  transition: transform 0.4s ease;
}

.lnk-teaser__text {
  border: 1px solid #d0d0d0;
  z-index: 2;
  position: absolute;
  bottom: 0;
  display: block;
  hyphens: auto;
  width: calc(100% - 30px);
  color: #2d3036;
  background-color: #ffffff;
  padding: 12px 8px;
  transition: ease max-height 0.4s;
  min-height: 110px;
  text-align: left;
}

@media (min-width: 1200px) {
  .lnk-teaser__text {
    min-height: 185px;
  }
}

.lnk-teaser--one-carousel-slide-item {
  padding-right: 0 !important;
}

.lnk-teaser--one-carousel-slide-item .lnk-teaser__text {
  width: 100% !important;
}

.lnk-teaser__headline {
  padding-top: 8px;
  margin-bottom: 0.5rem;
}

.lnk-teaser__subheadline,
.lnk-teaser p {
  width: 100%;
  transition: ease max-height 0.4s;
  overflow: hidden;
  margin: 0;
}

@media (min-width: 992px) {
  .lnk-teaser p {
    max-height: none;
  }
  .lnk-teaser__subheadline {
    max-height: 0;
  }
}

.lnk-teaser.lnk-component {
  margin-top: 0;
  padding-right: 30px;
}

.lnk-teaser-carousel.lnk-teaser-carousel--has-preview .lnk-teaser--one-carousel-slide-item {
  padding-right: 8px !important;
}

@media (min-width: 768px) {
  .lnk-teaser-carousel.lnk-teaser-carousel--has-preview .lnk-teaser--one-carousel-slide-item {
    padding-right: 24px !important;
  }
}

.lnk-teaser-carousel--small-teaser .lnk-teaser {
  height: auto !important;
}

.lnk-teaser-carousel--small-teaser .lnk-teaser__image {
  height: 80px !important;
  width: 80px;
  align-self: center;
  flex-grow: unset;
}

.lnk-teaser-carousel--small-teaser .lnk-teaser__src {
  background-size: auto !important;
}

.lnk-teaser-carousel--small-teaser .lnk-teaser__image {
  height: 80px !important;
  width: 80px;
  align-self: center;
  flex-grow: unset;
}

.lnk-teaser-carousel--small-teaser .lnk-teaser__headline {
  margin-bottom: 5px;
}

.lnk-teaser-carousel--small-teaser .lnk-teaser__subheadline {
  margin-bottom: 12px;
}

.lnk-teaser-carousel--small-teaser .lnk-teaser__text {
  padding-top: 0;
  text-align: center;
  border: unset;
}

.lnk-teaser-carousel--small-teaser .lnk-teaser__text p {
  max-height: unset !important;
  font-size: 16px !important;
}

.lnk-text-media {
  clear: both;
  /* Carousel Styles */
  /* prettier-ignore */
  /* prettier-ignore */
  /* prettier-ignore */
}

.lnk-text-media > .row {
  display: block;
}

.lnk-text-media__image {
  position: relative;
  z-index: 1;
  flex: 0;
  float: left;
}

.lnk-text-media__image img {
  width: 100%;
}

@media (min-width: 992px) {
  .lnk-text-media__image {
    margin-right: 15px;
  }
}

.lnk-text-media__text li {
  font-size: 1rem !important;
  line-height: 1.55;
}

@media (min-width: 768px) {
  .lnk-text-media__text li {
    font-size: 1.125rem !important;
  }
}

.lnk-text-media__text a {
  word-wrap: anywhere;
}

.lnk-text-media--image-right .lnk-text-media__image {
  float: right;
}

@media (min-width: 992px) {
  .lnk-text-media--image-right .lnk-text-media__image {
    margin-left: 15px;
  }
}

.lnk-text-media--image-top-full .lnk-text-media__image, .lnk-text-media--image-bottom-full .lnk-text-media__image {
  float: none;
}

.lnk-text-media--image-bottom-full > .row {
  display: flex;
}

.lnk-text-media--image-bottom-full .lnk-text-media__image {
  flex: 0 0 100%;
  order: 1;
}

@media (min-width: 992px) {
  .lnk-text-media.lnk-text-media--image-align-top.lnk-text-media--headlines-next-to-image, .lnk-text-media.lnk-text-media--image-align-top.lnk-text-media--no-headlines {
    padding-top: 100px;
  }
}

@media (min-width: 992px) {
  .lnk-text-media.lnk-text-media--image-align-top .row > .lnk-text-media__headlines {
    padding-bottom: 100px;
  }
}

.lnk-text-media.lnk-text-media--image-align-top .lnk-text-media__image {
  margin-top: -100px !important;
}

@media (min-width: 992px) {
  .lnk-text-media.lnk-text-media--image-align-top .lnk-text-media__image {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .lnk-text-media.lnk-text-media--image-align-top .lnk-text-media__image {
    margin-top: 0 !important;
    padding-top: calc(20px) !important;
  }
}

.lnk-text-media--text-2-cols p {
  column-count: 2;
}

.lnk-text-media .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel {
  margin-bottom: calc(20px + 20px + 60px);
}

.lnk-text-media .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel > .lnk-carousel-indicators--thumbs li {
  height: 60px;
}

.lnk-text-media .lnk-carousel.lnk-carousel--no-indicators > .carousel,
.lnk-text-media .lnk-carousel:not(.lnk-carousel--image-indicators) > .carousel {
  margin-bottom: calc(20px);
}

.lnk-text-media--image-top-full .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel,
.lnk-text-media--image-bottom-full .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel {
  margin-bottom: calc(40px + 80px);
}

.lnk-text-media--image-top-full .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel > .lnk-carousel-indicators--thumbs li,
.lnk-text-media--image-bottom-full .lnk-carousel.lnk-carousel--image-indicators:not(.lnk-carousel--no-indicators) > .carousel > .lnk-carousel-indicators--thumbs li {
  height: 80px;
}

.lnk-text-media p + h6 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.lnk-text-media a.btn {
  margin-bottom: 16px;
}

.lnk-read-more .lnk-component__headline {
  color: #373737 !important;
}

.lnk-read-more .lnk-component__subheadline {
  color: #2d3036 !important;
}

.lnk-read-more ul {
  font-size: 1.125rem;
}

.lnk-text-sideways {
  position: relative;
}

.lnk-text-sideways__container {
  padding: 45px 16px;
  width: 100%;
  background-color: rgba(127, 189, 39, 0.8);
}

@media (min-width: 576px) {
  .lnk-text-sideways__container {
    padding: 69px 191px 69px 91px;
  }
}

@media (min-width: 992px) {
  .lnk-text-sideways__container {
    background-color: unset;
    width: 50%;
    padding: 140px 0;
  }
}

.lnk-text-sideways__container .lnk-text-sideways__text,
.lnk-text-sideways__container .lnk-component__headline,
.lnk-text-sideways__container .lnk-component__subheadline,
.lnk-text-sideways__container .lnk-text-sideways__button {
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .lnk-text-sideways--left::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 43%;
    height: 100%;
    background-color: rgba(127, 189, 39, 0.8);
    z-index: 1;
  }
}

@media (min-width: 992px) {
  .lnk-text-sideways--left .lnk-text-sideways__text,
  .lnk-text-sideways--left .lnk-component__headline,
  .lnk-text-sideways--left .lnk-component__subheadline,
  .lnk-text-sideways--left .lnk-text-sideways__button {
    margin-right: calc( 7vw + 60px);
  }
}

@media (min-width: 1920px) {
  .lnk-text-sideways--left .lnk-text-sideways__text,
  .lnk-text-sideways--left .lnk-component__headline,
  .lnk-text-sideways--left .lnk-component__subheadline,
  .lnk-text-sideways--left .lnk-text-sideways__button {
    margin-right: calc( 1920px * 0.07 + 60px);
  }
}

.lnk-text-sideways--left .lnk-text-sideways__container {
  align-self: flex-start;
}

@media (min-width: 992px) {
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-text-sideways__text,
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-component__headline,
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-component__subheadline,
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-text-sideways__button {
    margin-left: calc( 100% - 912px / 2) !important;
  }
}

@media (min-width: 1200px) {
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-text-sideways__text,
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-component__headline,
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-component__subheadline,
  .lnk-text-sideways--left .lnk-text-sideways__container .lnk-text-sideways__button {
    margin-left: calc( 100% - 1092px / 2) !important;
  }
}

@media (min-width: 992px) {
  .lnk-text-sideways--right::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 43%;
    height: 100%;
    background-color: rgba(127, 189, 39, 0.8);
    z-index: 1;
  }
}

@media (min-width: 992px) {
  .lnk-text-sideways--right .lnk-text-sideways__text,
  .lnk-text-sideways--right .lnk-component__headline,
  .lnk-text-sideways--right .lnk-component__subheadline,
  .lnk-text-sideways--right .lnk-text-sideways__button {
    margin-left: calc( 7vw + 60px);
  }
}

@media (min-width: 1920px) {
  .lnk-text-sideways--right .lnk-text-sideways__text,
  .lnk-text-sideways--right .lnk-component__headline,
  .lnk-text-sideways--right .lnk-component__subheadline,
  .lnk-text-sideways--right .lnk-text-sideways__button {
    margin-left: calc( 1920px * 0.07 + 60px);
  }
}

.lnk-text-sideways--right .lnk-text-sideways__container {
  align-self: flex-end;
}

@media (min-width: 992px) {
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-text-sideways__text,
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-component__headline,
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-component__subheadline,
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-text-sideways__button {
    margin-right: calc( 100% - 912px / 2) !important;
  }
}

@media (min-width: 1200px) {
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-text-sideways__text,
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-component__headline,
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-component__subheadline,
  .lnk-text-sideways--right .lnk-text-sideways__container .lnk-text-sideways__button {
    margin-right: calc( 100% - 1092px / 2) !important;
  }
}

.lnk-text-sideways__button {
  margin-top: 20px;
}

@media (min-width: 576px) {
  .lnk-text-sideways__button {
    margin-top: 24px;
  }
}

@media (min-width: 768px) {
  .lnk-text-sideways__button {
    width: auto !important;
  }
}

.lnk-timeline-teaser {
  background-color: #e5f1d2;
}

.lnk-timeline-teaser a {
  height: 100%;
}

.lnk-timeline-teaser a:hover {
  text-decoration: none;
}

.lnk-timeline-teaser a:hover .lnk-timeline-teaser__src {
  transform: scale(1.1);
}

.lnk-timeline-teaser__image {
  height: 200px;
  overflow: hidden;
}

.lnk-timeline-teaser__src {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  transition: transform 0.4s ease;
}

.lnk-timeline-teaser__text {
  padding: 24px 16px;
  color: #2d3036;
  height: 285px;
  overflow: hidden;
}

.lnk-timeline-teaser__description p {
  padding: 0;
}

.lnk-timeline-teaser__icon {
  fill: #7fbd27;
  text-align: right;
}

.lnk-timeline-teaser__icon svg {
  width: 26px;
  height: 26px;
  margin: 16px 16px 16px 0;
}

.lnk-timeline-teaser__span {
  display: flex;
  flex-direction: row;
}

h1, .h1 {
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

h2, .lnk-cookie-modal__modal-headline, .h2 {
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

h3, .lnk-cookie-modal__manager-headline, .h3 {
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

h4, .h4 {
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

h5, .h5, .lnk-accordion__element-head {
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

h6, .h6 {
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

h1, .h1 {
  font-size: 1.875rem !important;
}

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.75rem !important;
  }
}

h2, .lnk-cookie-modal__modal-headline, .h2 {
  font-size: 1.5rem !important;
}

@media (min-width: 768px) {
  h2, .lnk-cookie-modal__modal-headline, .h2 {
    font-size: 2rem !important;
  }
}

@media (min-width: 1200px) {
  h2, .lnk-cookie-modal__modal-headline, .h2 {
    font-size: 2.125rem !important;
  }
}

h3, .lnk-cookie-modal__manager-headline, .h3 {
  font-size: 1.313rem !important;
}

@media (min-width: 768px) {
  h3, .lnk-cookie-modal__manager-headline, .h3 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 1200px) {
  h3, .lnk-cookie-modal__manager-headline, .h3 {
    font-size: 1.625rem !important;
  }
}

h4, .h4 {
  font-size: 1.25rem !important;
}

@media (min-width: 768px) {
  h4, .h4 {
    font-size: 1.625rem !important;
  }
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem !important;
  }
}

h5, .h5, .lnk-accordion__element-head {
  font-size: 1.125rem !important;
}

@media (min-width: 768px) {
  h5, .h5, .lnk-accordion__element-head {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 1200px) {
  h5, .h5, .lnk-accordion__element-head {
    font-size: 1.25rem !important;
  }
}

h6, .h6 {
  font-size: 1rem !important;
}

@media (min-width: 768px) {
  h6, .h6 {
    font-size: 1.125rem !important;
  }
}

@media (min-width: 1200px) {
  h6, .h6 {
    font-size: 1.125rem !important;
  }
}

h1, .h1 {
  line-height: 2.063rem;
}

@media (min-width: 768px) {
  h1, .h1 {
    line-height: 2.813rem;
  }
}

@media (min-width: 1200px) {
  h1, .h1 {
    line-height: 4.063rem;
  }
}

h2, .lnk-cookie-modal__modal-headline, .h2 {
  line-height: 1.563rem;
}

@media (min-width: 768px) {
  h2, .lnk-cookie-modal__modal-headline, .h2 {
    line-height: 2.5rem;
  }
}

@media (min-width: 1200px) {
  h2, .lnk-cookie-modal__modal-headline, .h2 {
    line-height: 2.5rem;
  }
}

h3, .lnk-cookie-modal__manager-headline, .h3 {
  line-height: 1.563rem;
}

@media (min-width: 768px) {
  h3, .lnk-cookie-modal__manager-headline, .h3 {
    line-height: 2.25rem;
  }
}

@media (min-width: 1200px) {
  h3, .lnk-cookie-modal__manager-headline, .h3 {
    line-height: 2rem;
  }
}

h4, .h4 {
  line-height: 1.563rem;
}

@media (min-width: 768px) {
  h4, .h4 {
    line-height: 2.125rem;
  }
}

@media (min-width: 1200px) {
  h4, .h4 {
    line-height: 1.938rem;
  }
}

h5, .h5, .lnk-accordion__element-head {
  line-height: 1.438rem;
}

@media (min-width: 768px) {
  h5, .h5, .lnk-accordion__element-head {
    line-height: 1.938rem;
  }
}

@media (min-width: 1200px) {
  h5, .h5, .lnk-accordion__element-head {
    line-height: 1.625rem;
  }
}

h6, .h6 {
  line-height: 1.313rem;
}

@media (min-width: 768px) {
  h6, .h6 {
    line-height: 1.438rem;
  }
}

@media (min-width: 1200px) {
  h6, .h6 {
    line-height: 1.438rem;
  }
}

h1, .h1 {
  letter-spacing: normal;
}

@media (min-width: 768px) {
  h1, .h1 {
    letter-spacing: normal;
  }
}

@media (min-width: 1200px) {
  h1, .h1 {
    letter-spacing: normal;
  }
}

h2, .lnk-cookie-modal__modal-headline, .h2 {
  letter-spacing: normal;
}

@media (min-width: 768px) {
  h2, .lnk-cookie-modal__modal-headline, .h2 {
    letter-spacing: normal;
  }
}

@media (min-width: 1200px) {
  h2, .lnk-cookie-modal__modal-headline, .h2 {
    letter-spacing: normal;
  }
}

h3, .lnk-cookie-modal__manager-headline, .h3 {
  letter-spacing: normal;
}

@media (min-width: 768px) {
  h3, .lnk-cookie-modal__manager-headline, .h3 {
    letter-spacing: normal;
  }
}

@media (min-width: 1200px) {
  h3, .lnk-cookie-modal__manager-headline, .h3 {
    letter-spacing: normal;
  }
}

h4, .h4 {
  letter-spacing: normal;
}

@media (min-width: 768px) {
  h4, .h4 {
    letter-spacing: normal;
  }
}

@media (min-width: 1200px) {
  h4, .h4 {
    letter-spacing: normal;
  }
}

h5, .h5, .lnk-accordion__element-head {
  letter-spacing: normal;
}

@media (min-width: 768px) {
  h5, .h5, .lnk-accordion__element-head {
    letter-spacing: normal;
  }
}

@media (min-width: 1200px) {
  h5, .h5, .lnk-accordion__element-head {
    letter-spacing: normal;
  }
}

h6, .h6 {
  letter-spacing: normal;
}

@media (min-width: 768px) {
  h6, .h6 {
    letter-spacing: normal;
  }
}

@media (min-width: 1200px) {
  h6, .h6 {
    letter-spacing: normal;
  }
}

p a:not(.btn) {
  text-transform: none;
}

p {
  font-family: "Avenir-Roman";
  font-size: 1rem !important;
  line-height: 1.55;
}

@media (min-width: 768px) {
  p {
    font-size: 1.125rem !important;
  }
}

strong {
  font-family: "Avenir-Black";
}

.lnk-welcome {
  text-align: center;
}

.lnk-welcome .lnk-component__subheadline {
  margin-bottom: 24px;
}

.lnk-welcome__text p {
  font-family: "Avenir-Roman";
  font-size: 1.125rem !important;
  line-height: 1.55;
  word-wrap: break-word;
}

@media (min-width: 768px) {
  .lnk-welcome__text p {
    font-size: 1.3125rem !important;
  }
}

.lnk-video {
  max-width: none;
}

.lnk-video .my-video-dimensions {
  width: auto !important;
  height: calc( 100vh - 60px - 3px);
}

@media (min-width: 992px) {
  .lnk-video .my-video-dimensions {
    height: calc( 100vh - 80px - 3px);
    align-items: center;
  }
}

.lnk-video .vjs-control-bar {
  padding-right: 15px !important;
  background-color: transparent !important;
  z-index: 2;
  bottom: 20px !important;
}

.lnk-video .vjs-big-play-button {
  z-index: 1;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  width: 223px !important;
  height: 223px !important;
  font-size: medium !important;
  cursor: pointer !important;
  padding: 0 !important;
  position: absolute !important;
}

.lnk-video .vjs-big-play-button:hover .vjs-icon-placeholder {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 58 58' width='58' height='58' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0a29 29 0 110 58 29 29 0 010-58zm0 6a23 23 0 100 46 23 23 0 000-46zm-8 12l22 11-22 11V18z' fill='%237fbd27' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
  opacity: 1;
}

.lnk-video .vjs-big-play-button .vjs-icon-placeholder {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 58 58' width='58' height='58' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0a29 29 0 110 58 29 29 0 010-58zm0 6a23 23 0 100 46 23 23 0 000-46zm-8 12l22 11-22 11V18z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
  opacity: 0.5;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
}

.lnk-video .vjs-big-play-button .vjs-icon-placeholder::before {
  content: '' !important;
}

.lnk-video .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder::before {
  content: '' !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 58 58' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0c16.0162577 0 29 12.9837423 29 29S45.0162577 58 29 58 0 45.0162577 0 29 12.9837423 0 29 0zm0 6C16.2974508 6 6 16.2974508 6 29s10.2974508 23 23 23 23-10.2974508 23-23S41.7025492 6 29 6z'/%3E%3Cpath d='M37 40.5c-1.93299662 0-3.5-1.5628011-3.5-3.493205V20.99320495C33.5 19.06396113 35.07093664 17.5 37 17.5c1.93299662 0 3.5 1.5628011 3.5 3.49320495V37.006795C40.5 38.9360389 38.92906336 40.5 37 40.5zm-15 0c-1.9329966 0-3.5-1.5628011-3.5-3.493205V20.99320495C18.5 19.06396113 20.0709366 17.5 22 17.5c1.9329966 0 3.5 1.5628011 3.5 3.49320495V37.006795C25.5 38.9360389 23.9290634 40.5 22 40.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  color: #ffffff;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-video .video-js .vjs-play-control.vjs-playing:hover .vjs-icon-placeholder::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 58 58' fill='%237FBD27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0c16.0162577 0 29 12.9837423 29 29S45.0162577 58 29 58 0 45.0162577 0 29 12.9837423 0 29 0zm0 6C16.2974508 6 6 16.2974508 6 29s10.2974508 23 23 23 23-10.2974508 23-23S41.7025492 6 29 6z'/%3E%3Cpath d='M37 40.5c-1.93299662 0-3.5-1.5628011-3.5-3.493205V20.99320495C33.5 19.06396113 35.07093664 17.5 37 17.5c1.93299662 0 3.5 1.5628011 3.5 3.49320495V37.006795C40.5 38.9360389 38.92906336 40.5 37 40.5zm-15 0c-1.9329966 0-3.5-1.5628011-3.5-3.493205V20.99320495C18.5 19.06396113 20.0709366 17.5 22 17.5c1.9329966 0 3.5 1.5628011 3.5 3.49320495V37.006795C25.5 38.9360389 23.9290634 40.5 22 40.5z'/%3E%3C/svg%3E");
}

.lnk-video .video-js .vjs-play-control.vjs-paused .vjs-icon-placeholder::before {
  content: '' !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 55 55' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.78885438.89442719L51.8445825 23.9222912c1.975914.987957 2.7768113 3.3906492 1.7888544 5.3665632-.3870549.7741096-1.0147448 1.4017996-1.7888544 1.7888544L5.78885438 54.1055728c-1.97591399.987957-4.37860615.1870596-5.36656314-1.7888544C.14458037 51.7612967 0 51.1488444 0 50.527864V4.47213595c0-2.20913899 1.790861-4 4-4 .62098038 0 1.23343264.14458037 1.78885438.42229124z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-video .video-js .vjs-play-control.vjs-paused:hover .vjs-icon-placeholder::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 55 55' fill='%237FBD27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.78885438.89442719L51.8445825 23.9222912c1.975914.987957 2.7768113 3.3906492 1.7888544 5.3665632-.3870549.7741096-1.0147448 1.4017996-1.7888544 1.7888544L5.78885438 54.1055728c-1.97591399.987957-4.37860615.1870596-5.36656314-1.7888544C.14458037 51.7612967 0 51.1488444 0 50.527864V4.47213595c0-2.20913899 1.790861-4 4-4 .62098038 0 1.23343264.14458037 1.78885438.42229124z' /%3E%3C/svg%3E");
}

.lnk-video .video-js .vjs-mute-control .vjs-icon-placeholder::before {
  content: '' !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 58 57' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20.750855v15.4982897c0 1.1045695.8954305 2 2 2h10.8888889l12.6909028 12.7991844c.7777237.7843594 2.0440422.7897387 2.8284016.012015C28.786947 50.6847947 29 50.1735134 29 49.6401358V7.35986394c0-1.1045695-.8954305-2-2-2-.5333776 0-1.0446589.21305304-1.4202083.59180671L12.8888889 18.750855H2c-1.1045695 0-2 .8954305-2 2zm43.5 7.7491449c0-5.7519955-3.2866667-10.6915622-8.0555556-13.0963512v26.1602052C40.2133333 39.191562 43.5 34.2519953 43.5 28.4999999zM35.4444444-1.4e-7v6.69441277C44.7566667 9.4891675 51.5555556 18.1984035 51.5555556 28.4999999c0 10.3015963-6.7988889 19.0108323-16.1111112 21.8055872v6.6944128C48.3655556 54.0427593 58 42.4087798 58 28.4999999 58 14.5912199 48.3655556 2.95724045 35.4444444-1.4e-7z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-video .video-js .vjs-mute-control:hover .vjs-icon-placeholder::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 58 57' fill='%237FBD27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20.750855v15.4982897c0 1.1045695.8954305 2 2 2h10.8888889l12.6909028 12.7991844c.7777237.7843594 2.0440422.7897387 2.8284016.012015C28.786947 50.6847947 29 50.1735134 29 49.6401358V7.35986394c0-1.1045695-.8954305-2-2-2-.5333776 0-1.0446589.21305304-1.4202083.59180671L12.8888889 18.750855H2c-1.1045695 0-2 .8954305-2 2zm43.5 7.7491449c0-5.7519955-3.2866667-10.6915622-8.0555556-13.0963512v26.1602052C40.2133333 39.191562 43.5 34.2519953 43.5 28.4999999zM35.4444444-1.4e-7v6.69441277C44.7566667 9.4891675 51.5555556 18.1984035 51.5555556 28.4999999c0 10.3015963-6.7988889 19.0108323-16.1111112 21.8055872v6.6944128C48.3655556 54.0427593 58 42.4087798 58 28.4999999 58 14.5912199 48.3655556 2.95724045 35.4444444-1.4e-7z' /%3E%3C/svg%3E");
}

.lnk-video .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder::before {
  content: '' !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 58 57' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20.750855v15.4982897c0 1.1045695.8954305 2 2 2h10.8888889l12.6909028 12.7991844c.7777237.7843594 2.0440422.7897387 2.8284016.012015C28.786947 50.6847947 29 50.1735134 29 49.6401358V7.35986394c0-1.1045695-.8954305-2-2-2-.5333776 0-1.0446589.21305304-1.4202083.59180671L12.8888889 18.750855H2c-1.1045695 0-2 .8954305-2 2zm43.5 7.7491449c0-5.7519955-3.2866667-10.6915622-8.0555556-13.0963512v26.1602052C40.2133333 39.191562 43.5 34.2519953 43.5 28.4999999zM35.4444444-1.4e-7v6.69441277C44.7566667 9.4891675 51.5555556 18.1984035 51.5555556 28.4999999c0 10.3015963-6.7988889 19.0108323-16.1111112 21.8055872v6.6944128C48.3655556 54.0427593 58 42.4087798 58 28.4999999 58 14.5912199 48.3655556 2.95724045 35.4444444-1.4e-7z'/%3E%3Cpath d='M33.75 27.916l.0833333.084-.0833333.083 21.6666834 21.6669833c1.3346816 1.3347001 1.3346741 3.4986592-.0000167 4.83335-1.3346882 1.3346882-3.4986452 1.3346882-4.8333334 0L28.917 32.917l-4.834-4.834L24 28l.083-.084L2.41668339 6.2499833c-1.33468814-1.3346697-1.33470312-3.49861176-.00003345-4.83329991l.00001673-.00001672c1.33467891-1.33467891 3.49862097-1.33467891 4.83329993 0a.00263098.00263098 0 00.0000167.00001672L28.916 23.083l4.834 4.833z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-video .video-js .vjs-mute-control.vjs-vol-0:hover .vjs-icon-placeholder::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 58 57' fill='%237FBD27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20.750855v15.4982897c0 1.1045695.8954305 2 2 2h10.8888889l12.6909028 12.7991844c.7777237.7843594 2.0440422.7897387 2.8284016.012015C28.786947 50.6847947 29 50.1735134 29 49.6401358V7.35986394c0-1.1045695-.8954305-2-2-2-.5333776 0-1.0446589.21305304-1.4202083.59180671L12.8888889 18.750855H2c-1.1045695 0-2 .8954305-2 2zm43.5 7.7491449c0-5.7519955-3.2866667-10.6915622-8.0555556-13.0963512v26.1602052C40.2133333 39.191562 43.5 34.2519953 43.5 28.4999999zM35.4444444-1.4e-7v6.69441277C44.7566667 9.4891675 51.5555556 18.1984035 51.5555556 28.4999999c0 10.3015963-6.7988889 19.0108323-16.1111112 21.8055872v6.6944128C48.3655556 54.0427593 58 42.4087798 58 28.4999999 58 14.5912199 48.3655556 2.95724045 35.4444444-1.4e-7z'/%3E%3Cpath d='M33.75 27.916l.0833333.084-.0833333.083 21.6666834 21.6669833c1.3346816 1.3347001 1.3346741 3.4986592-.0000167 4.83335-1.3346882 1.3346882-3.4986452 1.3346882-4.8333334 0L28.917 32.917l-4.834-4.834L24 28l.083-.084L2.41668339 6.2499833c-1.33468814-1.3346697-1.33470312-3.49861176-.00003345-4.83329991l.00001673-.00001672c1.33467891-1.33467891 3.49862097-1.33467891 4.83329993 0a.00263098.00263098 0 00.0000167.00001672L28.916 23.083l4.834 4.833z'/%3E%3C/svg%3E");
}

.lnk-video .video-js .vjs-fullscreen-control .vjs-icon-placeholder::before {
  content: '' !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 57 57' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.92062721 35.4604072l-.00080142 14.8088236H22.3404977c1.8586506 0 3.3653847 1.506734 3.3653847 3.3653846S24.1991483 57 22.3404977 57H0V35.4604072C0 33.5492771 1.54927709 32 3.46040724 32c1.91102672 0 3.46021998 1.5491933 3.46021998 3.46022l-1e-8.0001872zm27.73887519 14.808996l15.4196833.0007893V35.4604074c0-1.9111301 1.5492771-3.4604072 3.4604072-3.4604072 1.9111302 0 3.4604073 1.5492771 3.4604073 3.4604072v21.5395928H34.6595024c-1.8586506 0-3.3653846-1.506734-3.3653846-3.3653846 0-1.8585555 1.5066569-3.3652124 3.3652124-3.3652124h.0001722zm15.4198704-28.7298104l.0008014-14.80882357H34.6595023c-1.8586506 0-3.3653847-1.50673401-3.3653847-3.36538461C31.2941176 1.50673401 32.8008517 0 34.6595023 0H57v21.5395928C57 23.4507229 55.4507229 25 53.5395928 25c-1.9110268 0-3.46022-1.5491933-3.46022-3.46022v-.0001872zM22.3404976 6.7305968L6.9208143 6.7298075v14.80978508c0 1.91113015-1.54927709 3.46040724-3.46040724 3.46040724-1.91113014 0-3.46040724-1.54927709-3.46040724-3.46040724V-2e-7H22.3404976c1.8586506 0 3.3653846 1.506734 3.3653846 3.3653846 0 1.8585555-1.5066569 3.3652124-3.3652124 3.3652124h-.0001722z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-video .video-js .vjs-fullscreen-control:hover .vjs-icon-placeholder::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 57 57' fill='%237FBD27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.92062721 35.4604072l-.00080142 14.8088236H22.3404977c1.8586506 0 3.3653847 1.506734 3.3653847 3.3653846S24.1991483 57 22.3404977 57H0V35.4604072C0 33.5492771 1.54927709 32 3.46040724 32c1.91102672 0 3.46021998 1.5491933 3.46021998 3.46022l-1e-8.0001872zm27.73887519 14.808996l15.4196833.0007893V35.4604074c0-1.9111301 1.5492771-3.4604072 3.4604072-3.4604072 1.9111302 0 3.4604073 1.5492771 3.4604073 3.4604072v21.5395928H34.6595024c-1.8586506 0-3.3653846-1.506734-3.3653846-3.3653846 0-1.8585555 1.5066569-3.3652124 3.3652124-3.3652124h.0001722zm15.4198704-28.7298104l.0008014-14.80882357H34.6595023c-1.8586506 0-3.3653847-1.50673401-3.3653847-3.36538461C31.2941176 1.50673401 32.8008517 0 34.6595023 0H57v21.5395928C57 23.4507229 55.4507229 25 53.5395928 25c-1.9110268 0-3.46022-1.5491933-3.46022-3.46022v-.0001872zM22.3404976 6.7305968L6.9208143 6.7298075v14.80978508c0 1.91113015-1.54927709 3.46040724-3.46040724 3.46040724-1.91113014 0-3.46040724-1.54927709-3.46040724-3.46040724V-2e-7H22.3404976c1.8586506 0 3.3653846 1.506734 3.3653846 3.3653846 0 1.8585555-1.5066569 3.3652124-3.3652124 3.3652124h-.0001722z' /%3E%3C/svg%3E");
}

.lnk-video .vjs-picture-in-picture-control {
  display: none;
}

.lnk-media-carousel .vjs-poster {
  background-size: cover;
}

.lnk-media-carousel .slider-nav-wrapper .slick-track {
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  .lnk-media-carousel .slick-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.lnk-media-carousel__thumb {
  background-size: cover;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
}

.lnk-media-carousel__thumb:hover::before {
  content: ' ';
  width: calc(100% - (20px));
  height: 4px;
  position: absolute;
  bottom: 0;
  background-color: #7fbd27;
}

.lnk-media-carousel__thumb img {
  width: 100%;
}

.lnk-media-carousel__thumb--video {
  position: relative;
  cursor: pointer;
}

.lnk-media-carousel__thumb--video::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 58 58' width='58' height='58' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0a29 29 0 110 58 29 29 0 010-58zm0 6a23 23 0 100 46 23 23 0 000-46zm-8 12l22 11-22 11V18z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
  display: inline-block;
  width: 58px !important;
  height: 58px !important;
  background-repeat: no-repeat !important;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-media-carousel__thumb--video:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 58 58' width='58' height='58' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29 0a29 29 0 110 58 29 29 0 010-58zm0 6a23 23 0 100 46 23 23 0 000-46zm-8 12l22 11-22 11V18z' fill='%237fbd27' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
}

@media (max-width: 575.98px) {
  .lnk-media-carousel .slick-list {
    order: 1;
  }
}

.lnk-media-carousel .icon-wrapper {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: inline-block;
  width: 36px;
  height: 36px;
  position: relative;
}

.lnk-media-carousel .icon-wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 48px;
  width: 48px;
  fill: #7fbd27;
}

.lnk-media-carousel .slider-nav-wrapper:hover .icon-wrapper {
  border: none;
  background-color: transparent;
}

.lnk-media-carousel .slider-nav-wrapper:hover .icon-wrapper svg {
  fill: #7fbd27;
}

@media (max-width: 575.98px) {
  .lnk-media-carousel .slick-controls {
    display: flex;
    justify-content: center;
  }
}

.lnk-media-carousel .prev-arrow {
  fill: #7fbd27;
  position: absolute;
  left: 26px;
  transform: translate(-100%, -50%);
  top: 50%;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .lnk-media-carousel .prev-arrow {
    position: relative;
    transform: none;
    margin-right: 10px;
    margin-top: 16px;
    left: auto;
  }
}

.lnk-media-carousel .next-arrow {
  fill: #7fbd27;
  position: absolute;
  right: 26px;
  transform: translate(100%, -50%);
  top: 50%;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .lnk-media-carousel .next-arrow {
    position: relative;
    transform: none;
    margin-left: 10px;
    margin-top: 16px;
    right: auto;
  }
}

.lnk-media-carousel__element {
  position: relative;
}

.lnk-media-carousel__element--caption {
  position: absolute;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 52, 52, 0.6);
}

.lnk-media-carousel__element--caption p {
  font-size: 0.75rem;
  margin: 8px;
}

.lnk-fifty-fifty {
  margin: 0 auto;
}

.lnk-fifty-fifty img {
  object-fit: cover;
  height: 100%;
}

@media (min-width: 992px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) {
    /* prettier-ignore */
  }
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__image {
    order: 1;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text h6 {
    margin-left: calc(100% - 640px / 2);
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text h6 {
    margin-left: calc(100% - 912px / 2);
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(even) .lnk-fifty-fifty__text h6 {
    margin-left: calc(100% - 1092px / 2);
  }
}

@media (min-width: 992px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) {
    /* prettier-ignore */
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text h6 {
    margin-right: calc(100% - 640px / 2);
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text h6 {
    margin-right: calc(100% - 912px / 2);
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty:not(.lnk-fifty-fifty--startright) .row:nth-of-type(odd) .lnk-fifty-fifty__text h6 {
    margin-right: calc(100% - 1092px / 2);
  }
}

@media (min-width: 992px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) {
    /* prettier-ignore */
  }
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__image {
    order: 1;
  }
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h6 {
    color: #ffffff;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h6 {
    margin-left: calc(100% - 640px / 2);
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h6 {
    margin-left: calc(100% - 912px / 2);
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(odd) .lnk-fifty-fifty__text h6 {
    margin-left: calc(100% - 1092px / 2);
  }
}

@media (min-width: 992px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(even) {
    /* prettier-ignore */
  }
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h6 {
    color: #2d3036;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h6 {
    margin-right: calc(100% - 640px / 2);
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h6 {
    margin-right: calc(100% - 912px / 2);
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text p,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h1.lnk-component__headline,
  .lnk-fifty-fifty--startright .row:nth-of-type(even) .lnk-fifty-fifty__text h6 {
    margin-right: calc(100% - 1092px / 2);
  }
}

.lnk-fifty-fifty .row:nth-of-type(odd) {
  background-color: #7fbd27;
}

.lnk-fifty-fifty .row:nth-of-type(odd) .lnk-fifty-fifty__text {
  color: #ffffff;
}

.lnk-fifty-fifty .row:nth-of-type(odd) .lnk-fifty-fifty__text .lnk-component__headline {
  color: #ffffff;
}

.lnk-fifty-fifty .row:nth-of-type(odd) .lnk-fifty-fifty__text a {
  color: #2d3036;
}

.lnk-fifty-fifty .row:nth-of-type(even) {
  background-color: #ffffff;
}

.lnk-fifty-fifty .row:nth-of-type(even) .lnk-fifty-fifty__text {
  color: #2d3036;
}

.lnk-fifty-fifty .row:nth-of-type(even) .lnk-fifty-fifty__text .lnk-component__headline {
  color: #2d3036;
}

.lnk-fifty-fifty__text {
  min-height: 200px;
  padding: 28px 32px 0 32px !important;
}

.lnk-fifty-fifty__text h6 {
  font-family: "Geogrotesque-Bold";
}

.lnk-fifty-fifty .lnk-component__headline {
  color: #2d3036;
}

.lnk-social-links--colored.lnk-social-links--twitter > div {
  background-color: #00acee;
}

.lnk-social-links--colored.lnk-social-links--youtube > div {
  background-color: #bb0000;
}

.lnk-social-links--colored.lnk-social-links--instagram > div {
  background-color: #125688;
}

.lnk-social-links--colored.lnk-social-links--xing > div {
  background-color: #026466;
}

.lnk-social-links--colored.lnk-social-links--facebook > div {
  background-color: #3b5998;
}

.lnk-social-links--colored.lnk-social-links--linkedin > div {
  background-color: #007bb5;
}

.lnk-social-links--colored.lnk-social-links--whatsapp > div {
  background-color: #25d366;
}

.lnk-social-links--colored.lnk-social-links--snapchat > div {
  background-color: #fffc00;
}

.lnk-social-links--colored.lnk-social-links--skype > div {
  background-color: #00aff0;
}

.lnk-social-links > div {
  position: relative;
}

.lnk-social-links > div:hover {
  opacity: 0.75;
}

.lnk-social-links svg {
  position: absolute;
  fill: #ffffff;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lnk-badges__element {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-basis: auto;
  flex-grow: 1;
}

@media (max-width: 575.98px) {
  .lnk-badges__element {
    min-width: 150px;
  }
}

.lnk-badges__element svg {
  width: 100%;
  height: 100%;
}

.lnk-badges__element-title {
  visibility: hidden;
  color: #2d3036;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-family: "Geogrotesque-Bold";
  position: absolute;
  bottom: 44%;
}

.lnk-badges__element-subtitle {
  color: #2d3036;
  font-size: 0.58rem;
  font-family: "Geogrotesque-Bold";
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  bottom: 22%;
}

@media (min-width: 576px) {
  .lnk-badges__element-subtitle {
    font-size: 0.68rem;
  }
}

@media (min-width: 1200px) {
  .lnk-badges__element-subtitle {
    font-size: 0.68rem;
  }
}

@media (min-width: 576px) {
  .modal-content .lnk-badges__element-subtitle {
    font-size: 0.7rem;
  }
}

@media (min-width: 768px) {
  .modal-content .lnk-badges__element-subtitle {
    font-size: 0.59rem;
  }
}

@media (min-width: 992px) {
  .modal-content .lnk-badges__element-subtitle {
    font-size: 0.58rem;
  }
}

@media (min-width: 1200px) {
  .modal-content .lnk-badges__element-subtitle {
    font-size: 0.68rem;
  }
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lnk-hotspot__text {
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.8px;
  font-family: "Geogrotesque-Bold";
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .lnk-hotspot__text {
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 1px;
  }
}

@media (min-width: 992px) {
  .lnk-hotspot__text {
    font-size: 36px;
    line-height: 47px;
    letter-spacing: 1.2px;
  }
}

.lnk-hotspot__box {
  position: relative;
  display: block;
}

@media (max-width: 991.98px) {
  .lnk-hotspot__box {
    margin: 0;
  }
}

.lnk-hotspot__text-container {
  hyphens: auto;
  padding: 50px;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0%, rgba(128, 176, 200, 0.2) 100%);
}

@media (max-width: 991.98px) {
  .lnk-hotspot__text-container {
    border-radius: 8px 8px 0 0;
  }
}

.lnk-hotspot__elements:not(.lnk-hotspot__elements--edit-mode) {
  padding: 50px;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0%, rgba(128, 176, 200, 0.2) 100%);
}

.lnk-hotspot__map-wrapper {
  width: 600px;
  transform-origin: left top;
}

@media (min-width: 992px) {
  .lnk-hotspot__map-wrapper {
    position: absolute;
    top: 50px;
    right: 0;
    transform-origin: right top;
  }
}

.lnk-hotspot__map-container {
  height: 100%;
  position: relative;
}

.lnk-hotspot__map {
  width: 100%;
}

.lnk-hotspot__pin {
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
}

.lnk-hotspot__pin-icon {
  width: 20px;
  height: 20px;
}

.lnk-hotspot__pin-icon svg {
  vertical-align: unset;
}

.lnk-hotspot__accordion {
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0%, rgba(128, 176, 200, 0.2) 100%);
  border-radius: 0 0 8px 8px;
  padding: 50px;
}

.lnk-hotspot__accordion .lnk-accordion__element {
  border-top: unset;
  border-bottom: unset;
}

.lnk-hotspot__accordion .lnk-accordion__element-head {
  padding: 12px 0;
  justify-content: space-between;
  border-bottom: 1px solid #a8adb3;
  margin-bottom: 24px;
}

.lnk-hotspot__accordion .lnk-accordion__element-icon {
  margin-right: 0;
}

.lnk-hotspot-element__image--edit-mode {
  width: 200px !important;
}

.lnk-hotspot-element__image {
  width: 100%;
  margin-bottom: 40px;
}

.lnk-hotspot-element__horizontal-row {
  width: 100%;
  height: 1px;
  background-color: #bdc1c7;
  margin-bottom: 22px;
}

.lnk-hotspot-element__description {
  padding-left: 0;
  margin-bottom: 40px;
}

.lnk-hotspot-element__description-element {
  list-style-type: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.lnk-hotspot-element__description-element:not(:last-child) {
  margin-bottom: 15px;
}

.lnk-hotspot-element__icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.lnk-section--blog .lnk-accordion__element {
  border-color: #ffffff;
}

.lnk-section--blog .lnk-accordion__element-head svg {
  fill: #ffffff;
}

.lnk-section--blog .lnk-accordion__element-head:hover, .lnk-section--blog .lnk-accordion__element-head:not(.collapsed) {
  color: #ffffff;
}

.lnk-section--blog .lnk-accordion__element-head:hover svg, .lnk-section--blog .lnk-accordion__element-head:not(.collapsed) svg {
  fill: #ffffff;
}

.lnk-section--blog .btn-primary {
  border-color: #ffffff !important;
  color: #7fbd27 !important;
  background-color: #ffffff !important;
}

.lnk-section--blog .btn-primary:hover {
  background-color: transparent !important;
  color: #ffffff !important;
}

.lnk-section--blog .btn-primary:active {
  border-color: #ffffff !important;
  color: #7fbd27 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.lnk-section--blog .btn-secondary {
  border-color: #ffffff !important;
  color: #ffffff;
  background-color: #7fbd27;
  text-transform: none;
  line-height: normal;
}

.lnk-section--blog .btn-secondary:hover {
  background-color: #ffffff;
  color: #7fbd27;
  border-color: #7fbd27;
}

.lnk-section--blog .btn-secondary:active {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.9) !important;
  color: #ffffff !important;
}

.lnk-section--blog .lnk-component__headline {
  color: #ffffff;
}

.lnk-section--blog .lnk-component__subheadline {
  color: #ffffff;
}

.lnk-section--blog .lnk-hotspot__text-container {
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0, rgba(255, 255, 255, 0.2) 100%) !important;
}

.lnk-section--blog .lnk-hotspot__elements:not(.lnk-hotspot__elements--edit-mode) {
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0, rgba(255, 255, 255, 0.2) 100%) !important;
}

.lnk-section--blog .lnk-hotspot__accordion {
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0, rgba(255, 255, 255, 0.2) 100%) !important;
}

.lnk-section--blog .lnk-hotspot__accordion .lnk-accordion__element-head {
  border-bottom: 1px solid #ffffff !important;
}

.lnk-section--blog .lnk-hotspot-element__horizontal-row {
  background-color: #ffffff;
}

.lnk-section--blog .lnk-interview__mark-start svg,
.lnk-section--blog .lnk-interview__mark-end svg {
  fill: #ffffff;
}

.lnk-section--blog .lnk-interview__author {
  border-top: 1px #ffffff solid;
}

.lnk-section--blog .lnk-interview__element-head .lnk-interview__icon svg {
  fill: #ffffff;
}

.lnk-section--blog .lnk-interview__element-head:hover {
  color: #ffffff;
}

.lnk-section--blog .lnk-interview__element-head:hover .lnk-interview__icon svg {
  fill: #ffffff;
}

.lnk-section--blog .lnk-interview__icon {
  fill: #ffffff;
}

.lnk-section--blog .lnk-interview__element {
  border-top: 1px solid #ffffff;
}

.lnk-section--blog .lnk-quotation svg {
  fill: #ffffff;
  opacity: 0.2;
}

.lnk-section--blog .lnk-quotation__author {
  border-color: #ffffff;
}

.lnk-section--blog {
  background-color: #7fbd27;
  color: #ffffff;
  padding: 24px 0;
}

.lnk-section--blog .lnk-section__headline {
  color: #ffffff;
}

.lnk-section--blog .lnk-section__subheadline {
  color: #ffffff;
}

.lnk-section--blog a:not(.btn) {
  color: #ffffff;
}

.lnk-section--blog table tr {
  outline: none;
}

.lnk-section--blog table th {
  background-color: #e5f1d2;
  border: none;
  color: #2d3036;
}

.lnk-section--blog table td {
  border: none;
  background-color: #ffffff;
  color: #2d3036;
}

.lnk-section--blog .lnk-teaser-carousel--small-teaser .lnk-teaser__text {
  background-color: transparent;
  color: #ffffff;
}

.lnk-section--image .lnk-accordion__element {
  border-color: #ffffff;
}

.lnk-section--image .lnk-accordion__element-head svg {
  fill: #ffffff;
}

.lnk-section--image .lnk-accordion__element-head:hover, .lnk-section--image .lnk-accordion__element-head:not(.collapsed) {
  color: #ffffff;
}

.lnk-section--image .lnk-accordion__element-head:hover svg, .lnk-section--image .lnk-accordion__element-head:not(.collapsed) svg {
  fill: #ffffff;
}

.lnk-section--image .btn-primary {
  border-color: #ffffff !important;
  color: #7fbd27 !important;
  background-color: #ffffff !important;
}

.lnk-section--image .btn-primary:hover {
  background-color: transparent !important;
  color: #ffffff !important;
}

.lnk-section--image .btn-primary:active {
  border-color: #ffffff !important;
  color: #7fbd27 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.lnk-section--image .btn-secondary {
  border-color: #ffffff !important;
  color: #ffffff;
  background-color: transparent;
}

.lnk-section--image .btn-secondary:hover {
  background-color: #ffffff;
  color: #7fbd27;
}

.lnk-section--image .btn-secondary:active {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.9) !important;
  color: #7fbd27 !important;
}

.lnk-section--image .lnk-component__headline {
  color: #ffffff;
}

.lnk-section--image .lnk-component__subheadline {
  color: #ffffff;
}

.lnk-section--image .lnk-hotspot__text-container {
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0, rgba(255, 255, 255, 0.2) 100%) !important;
}

.lnk-section--image .lnk-hotspot__elements:not(.lnk-hotspot__elements--edit-mode) {
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0, rgba(255, 255, 255, 0.2) 100%) !important;
}

.lnk-section--image .lnk-hotspot__accordion {
  background: linear-gradient(135deg, rgba(128, 176, 200, 0.05) 0, rgba(255, 255, 255, 0.2) 100%) !important;
}

.lnk-section--image .lnk-hotspot__accordion .lnk-accordion__element-head {
  border-bottom: 1px solid #ffffff !important;
}

.lnk-section--image .lnk-hotspot-element__horizontal-row {
  background-color: #ffffff;
}

.lnk-section--image .lnk-interview__mark-start svg,
.lnk-section--image .lnk-interview__mark-end svg {
  fill: #ffffff;
}

.lnk-section--image .lnk-interview__author {
  border-top: 1px #ffffff solid;
}

.lnk-section--image .lnk-interview__element-head .lnk-interview__icon svg {
  fill: #ffffff;
}

.lnk-section--image .lnk-interview__element-head:hover {
  color: #ffffff;
}

.lnk-section--image .lnk-interview__element-head:hover .lnk-interview__icon svg {
  fill: #ffffff;
}

.lnk-section--image .lnk-interview__icon {
  fill: #ffffff;
}

.lnk-section--image .lnk-interview__element {
  border-top: 1px solid #ffffff;
}

.modal-open .lnk-section--image {
  z-index: 20;
}

.modal-open .lnk-section--image .container {
  z-index: auto;
}

.lnk-lightbox--ajax-link .lnk-section .container {
  z-index: 20;
}

.lnk-section--image .lnk-quotation svg {
  fill: #ffffff;
  opacity: 0.3;
}

.lnk-section--image .lnk-quotation__author {
  border-color: #ffffff;
}

.lnk-section--image {
  max-width: none;
  margin: 0 auto;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  z-index: 1;
}

.lnk-section--image .lnk-section__headline {
  color: #ffffff;
}

.lnk-section--image .lnk-section__subheadline {
  color: #ffffff;
}

.lnk-section--image a {
  color: #ffffff;
  font-family: "Avenir-Roman";
}

.lnk-section--image > * {
  position: relative;
  z-index: 20;
}

.lnk-section--image::before {
  position: absolute;
  overflow-y: hidden;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 17;
  background-color: #2d3036;
}

.lnk-section--image-lowest:before {
  opacity: 0.9;
}

.lnk-section--image-low:before {
  opacity: 0.7;
}

.lnk-section--image-medium:before {
  opacity: 0.55;
}

.lnk-section--image-high:before {
  opacity: 0.4;
}

.lnk-section--image-highest:before {
  opacity: 0.2;
}

.lnk-section--image .lnk-teaser-carousel--small-teaser .lnk-teaser__text {
  background-color: transparent;
  color: #ffffff;
}

.lnk-section--image table th {
  color: #2d3036;
}

.lnk-section--image table td {
  color: #2d3036;
}

.lnk-section--highlight .lnk-accordion__element {
  border-color: #2d3036;
}

.lnk-section--highlight .lnk-accordion__element-head svg {
  fill: #2d3036;
}

.lnk-section--highlight .lnk-accordion__element-head:hover, .lnk-section--highlight .lnk-accordion__element-head:not(.collapsed) {
  color: #2d3036;
}

.lnk-section--highlight .lnk-accordion__element-head:hover svg, .lnk-section--highlight .lnk-accordion__element-head:not(.collapsed) svg {
  fill: #2d3036;
}

.lnk-section--highlight .btn-primary {
  border-color: #ffffff;
  color: #7fbd27;
  background-color: #ffffff;
}

.lnk-section--highlight .btn-primary:hover {
  background-color: transparent;
  color: #ffffff;
}

.lnk-section--highlight .btn-primary:active {
  border-color: #ffffff !important;
  color: #7fbd27 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.lnk-section--highlight .btn-secondary {
  border-color: #7fbd27;
  color: #7fbd27;
  background-color: transparent;
}

.lnk-section--highlight .btn-secondary:hover {
  background-color: #7fbd27;
  color: #ffffff;
}

.lnk-section--highlight .btn-secondary:active {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.9) !important;
  color: #7fbd27 !important;
}

.lnk-section--highlight .lnk-component__headline {
  color: #2d3036;
}

.lnk-section--highlight .lnk-component__subheadline {
  color: #2d3036;
}

.lnk-section--highlight .lnk-hotspot__text-container {
  background: rgba(255, 255, 255, 0.4) !important;
}

.lnk-section--highlight .lnk-hotspot__elements:not(.lnk-hotspot__elements--edit-mode) {
  background: rgba(255, 255, 255, 0.4) !important;
}

.lnk-section--highlight .lnk-hotspot__accordion {
  background: rgba(255, 255, 255, 0.4) !important;
}

.lnk-section--highlight .lnk-interview__mark-start svg,
.lnk-section--highlight .lnk-interview__mark-end svg {
  fill: #2d3036;
}

.lnk-section--highlight .lnk-interview__author {
  border-top: 1px #2d3036 solid;
}

.lnk-section--highlight .lnk-interview__element-head .lnk-interview__icon svg {
  fill: #2d3036;
}

.lnk-section--highlight .lnk-interview__element-head:hover {
  color: #2d3036;
}

.lnk-section--highlight .lnk-interview__element-head:hover .lnk-interview__icon svg {
  fill: #2d3036;
}

.lnk-section--highlight .lnk-interview__icon {
  fill: #2d3036;
}

.lnk-section--highlight .lnk-interview__element {
  border-top: 1px solid #2d3036;
}

.lnk-section--highlight .lnk-quotation svg {
  fill: #2d3036;
  opacity: 0.3;
}

.lnk-section--highlight .lnk-quotation__author {
  border-color: #2d3036;
}

.lnk-section--highlight .lnk-teaser-carousel--small-teaser .lnk-teaser__text {
  background-color: transparent;
}

.lnk-section--highlight {
  background-color: #bfde93;
  color: #2d3036;
}

.lnk-section--highlight .lnk-section__headline {
  color: #2d3036;
}

.lnk-section--highlight .lnk-section__subheadline {
  color: #2d3036;
}

.lnk-section--full-width {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.lnk-section--full-width .container {
  padding: 0 !important;
  max-width: none !important;
}

.lnk-cookie__buttons .btn[data-accept-cookie] {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #7fbd27 !important;
}

.lnk-cookie__buttons .btn[data-accept-cookie]:hover {
  background-color: transparent !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.lnk-cookie__buttons .btn[data-config-cookie],
.lnk-cookie__buttons .btn[data-decline-cookie] {
  background-color: transparent !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.lnk-cookie__buttons .btn[data-config-cookie]:hover,
.lnk-cookie__buttons .btn[data-decline-cookie]:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #7fbd27 !important;
}

.lnk-cookie-modal .modal-header {
  border: unset;
}

.lnk-cookie-modal .modal-header .close {
  color: white;
  opacity: 1;
}

.lnk-cookie-modal .modal-content {
  background-color: #373737;
  color: #ffffff;
}

.lnk-cookie-modal .modal-body hr {
  display: none;
}

.lnk-cookie-modal .modal-body p,
.lnk-cookie-modal .modal-body .checkboxdescription {
  font-size: 16px;
  padding-top: 8px;
}

.lnk-cookie-modal .modal-footer {
  border: unset;
}

.lnk-cookie-modal .modal-footer [data-config-cookie-cancel-manager] {
  background-color: transparent !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.lnk-cookie-modal .modal-footer [data-config-cookie-cancel-manager]:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #7fbd27 !important;
}

.lnk-cookie-modal .modal-footer [data-config-cookie-savechoice] {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #7fbd27 !important;
}

.lnk-cookie-modal .modal-footer [data-config-cookie-savechoice]:hover {
  background-color: transparent !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

@font-face {
  font-family: 'Geogrotesque-Bold';
  font-display: auto;
  src: url("../../client-theme/fonts/1185137/9e3ed394-6308-4d0c-b4c8-ed6b8a5fabf6.eot?#iefix");
  src: url("../../client-theme/fonts/1185137/9e3ed394-6308-4d0c-b4c8-ed6b8a5fabf6.eot?#iefix") format("eot"), url("../../client-theme/fonts/1185137/9a2b0468-1fa1-4d69-a737-7c158533cb09.woff2") format("woff2"), url("../../client-theme/fonts/1185137/6b2312d2-7fdd-4980-857e-925b5ea36283.woff") format("woff"), url("../../client-theme/fonts/1185137/be893e23-5af4-47f2-a38b-685ef2abd3c3.ttf") format("truetype");
}

/* unused */
@font-face {
  font-family: 'Geogrotesque-Light';
  font-display: auto;
  src: url("../../client-theme/fonts/1185155/fdeead73-e360-4ef1-b0b0-2a13ab5ca1f7.eot?#iefix");
  src: url("../../client-theme/fonts/1185155/fdeead73-e360-4ef1-b0b0-2a13ab5ca1f7.eot?#iefix") format("eot"), url("../../client-theme/fonts/1185155/69fc4714-05d2-488b-838b-5925ef0197dd.woff2") format("woff2"), url("../../client-theme/fonts/1185155/d35a7b59-82a2-4f8a-8287-cc5423e1f0ab.woff") format("woff"), url("../../client-theme/fonts/1185155/b75d0fcc-c53f-4cf0-887e-d20337ce96f9.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir-Roman';
  font-display: auto;
  src: url("../../client-theme/fonts/1475508/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
  src: url("../../client-theme/fonts/1475508/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"), url("../../client-theme/fonts/1475508/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"), url("../../client-theme/fonts/1475508/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"), url("../../client-theme/fonts/1475508/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
}

/*unused*/
@font-face {
  font-family: 'Avenir-Black';
  font-display: auto;
  src: url("../../client-theme/fonts/1475544/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
  src: url("../../client-theme/fonts/1475544/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"), url("../../client-theme/fonts/1475544/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"), url("../../client-theme/fonts/1475544/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"), url("../../client-theme/fonts/1475544/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype");
}

.lnk-quotation .lnk-carousel .carousel-indicators li {
  width: 12px;
  height: 12px;
  border: 1px solid #2d3036;
  background: transparent;
  border-radius: 50% 50%;
  flex-grow: unset;
}

.lnk-quotation .lnk-carousel .carousel-indicators li.active {
  background-color: #2d3036;
}

.lnk-quotation .lnk-carousel .carousel-indicators li:not(:last-child) {
  margin-right: 20px;
}

p a:not(.btn) {
  text-decoration: underline;
}

p a:not(.btn):hover {
  text-decoration-thickness: 2px;
}

/*# sourceMappingURL=main.css.map */